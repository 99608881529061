import React from "react";
import Button from "../../Button";
import Tip from "./Tip";
export default function Index() {
  return (
    <div className="flex h-full flex-col justify-between">
      <div>
        <h4 className="text-20to24 font-semibold">
          Not the right model you expected?
        </h4>
        <div className="my-22to34 rounded-3xl border p-30to40">
          <h6 className="text-18to20 mb-22to34 font-medium">
            Here’s what you can do
          </h6>
          <ul>
            <Tip text="Tip 1: Try capturing a full 360 view of the model" />
            <Tip text="Tip 2: Try capturing a full 360 view of the model" />
            <Tip text="Tip 3: Try capturing a full 360 view of the model" />
          </ul>
        </div>
      </div>
      <div className="flex justify-between">
        <Button text="Capture Video" className="mr-4 w-full md:mr-5" />
        <Button text="Upload Video" outline className="w-full" />
      </div>
    </div>
  );
}

import React from "react";

function Uploading({ iconClassName, height, width, iconColor }) {
  return (
    <svg
      className={iconClassName}
      width={width || "74"}
      height={height || "74"}
      viewBox="0 0 74 74"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M37 74c20.434 0 37-16.566 37-37S57.434 0 37 0 0 16.566 0 37s16.566 37 37 37z"
        fill="#C8F1E1"
      />
      <path
        d="M49.719 34.302 37 20.812l-12.719 13.49M37 20.813v32.374"
        stroke="#137359"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Uploading;

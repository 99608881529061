import React from "react";

function Home({ color, height, width, iconClassName }) {
  return (
    <svg
      className={iconClassName}
      width={width || 17}
      height={height || 18}
      viewBox="0 0 17 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 6.6 8.5 1 16 6.6v8.8c0 .424-.176.831-.488 1.131-.313.3-.737.469-1.179.469H2.667c-.442 0-.866-.169-1.179-.469A1.568 1.568 0 0 1 1 15.4V6.6z"
        stroke={color || "#444"}
        strokeMiterlimit="22.926"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 17V9h5v8"
        stroke={color || "#444"}
        strokeMiterlimit="22.926"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Home;

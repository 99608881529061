import React from "react";

function Processing({ iconClassName, heihgt, width, style }) {
  return (
    <svg
      style={style}
      className={iconClassName}
      width={width || "86"}
      height={heihgt || "75"}
      viewBox="0 0 86 75"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M43 1v46.382"
        stroke="#90D7B3"
        strokeWidth="2"
        strokeMiterlimit="22.926"
        strokeLinecap="round"
        strokeDasharray="2 5"
      />
      <path
        d="M65.615 50.618 85 66.798M20.385 50.618 1 66.798"
        stroke="#90D7B3"
        strokeWidth="2"
        strokeMiterlimit="22.926"
        strokeLinecap="round"
        strokeDasharray="2 4"
      />
      <path
        d="M13.506 26.348c-.574.27-1.26.026-1.534-.547a1.146 1.146 0 0 1 .549-1.528l.985 2.072v.003zm1.095-3.054.985 2.073-2.08.98-.985-2.072 2.08-.98zm0 0a1.155 1.155 0 0 1 1.534.547 1.146 1.146 0 0 1-.549 1.528l-.985-2.072v-.003zm7.228-.87c-.574.27-1.26.026-1.534-.547a1.146 1.146 0 0 1 .548-1.528l.986 2.072v.003zm1.095-3.054.985 2.073-2.08.98-.986-2.072 2.08-.98zm0 0a1.155 1.155 0 0 1 1.533.547 1.146 1.146 0 0 1-.548 1.528l-.985-2.072v-.003zm7.227-.87c-.573.27-1.26.026-1.533-.547a1.146 1.146 0 0 1 .548-1.528l.986 2.072v.003zm1.095-3.054.986 2.073-2.08.98-.986-2.072 2.08-.98zm0 0a1.155 1.155 0 0 1 1.534.547 1.146 1.146 0 0 1-.548 1.528l-.986-2.072v-.003zm7.228-.87c-.573.27-1.26.027-1.533-.547a1.146 1.146 0 0 1 .548-1.528l.985 2.072v.003zm1.095-3.053.985 2.072-2.08.98-.985-2.071 2.08-.981zm0 0a1.155 1.155 0 0 1 1.534.546 1.146 1.146 0 0 1-.549 1.528l-.985-2.072v-.002zm6.148 1.074a1.147 1.147 0 1 1 1.082-2.025l-1.082 2.023v.002zm3.113-.944-1.082 2.023-2.031-1.079 1.082-2.023 2.031 1.079zm0 0a1.147 1.147 0 1 1-1.082 2.025l1.082-2.023v-.002zm5.014 5.259a1.147 1.147 0 1 1 1.083-2.026l-1.083 2.024v.002zm3.114-.945-1.082 2.024-2.032-1.079 1.083-2.023 2.031 1.078zm0 0a1.147 1.147 0 1 1-1.082 2.026l1.082-2.023v-.003zm5.014 5.26a1.147 1.147 0 1 1 1.082-2.026l-1.082 2.023v.003zm3.114-.945-1.082 2.023-2.032-1.078 1.082-2.024 2.032 1.079zm0 0a1.147 1.147 0 1 1-1.082 2.026l1.082-2.024v-.002zm5.014 5.26a1.147 1.147 0 1 1 1.082-2.026L70.1 25.539v.002zm3.114-.945L72.13 26.62 70.1 25.54l1.082-2.023 2.031 1.079zm0 0a1.147 1.147 0 1 1-1.083 2.025l1.082-2.023v-.002z"
        fill="#0F7358"
      />
      <path
        d="M43.171 42.277 10.863 25.79l-.172 32.722L43 75l.172-32.722z"
        fill="#90D7B3"
      />
      <path
        d="M42.825 42.277 75.133 25.79l.172 32.722L42.997 75l-.172-32.722z"
        fill="#268C71"
      />
    </svg>
  );
}

export default Processing;

import React, { useEffect, useState } from "react";
import Button from "../../Button";
import { fetchPendingBills, fetchcheckout } from "../../../service/modal";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function Index({ yesClicked, noClicked, token, userId }) {
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };
  return (
    <div className="ddnpopbox h-full w-full rounded-3xl">
      <div className="onDesktop">
        <div className="llft">
          <div className="ddnttl">Download for Customization</div>
          <img
            className="ddnimg"
            src="./images/ddn1.jpg"
            alt="Download for Customization"
          />
        </div>
        <div className="llrt">
          <div className="ddnttl">Share or Embed with Copy Link</div>
          <img
            className="ddnimg"
            src="./images/ddn2.jpg"
            alt="Share or Embed with Copy Link"
          />
        </div>
      </div>
      <div className="onMobdiv pt-5">
        <Slider {...settings} className="ddnsldr">
          <div className="llft1">
            <div className="ddnttl">Download for Customization</div>
            <img
              className="ddnimg"
              src="./images/ddn1.jpg"
              alt="Download for Customization"
            />
          </div>
          <div className="llrt1">
            <div className="ddnttl">Share or Embed with Copy Link</div>
            <img
              className="ddnimg"
              src="./images/ddn2.jpg"
              alt="Share or Embed with Copy Link"
            />
          </div>
        </Slider>
      </div>
    </div>
  );
}

import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Heading from "../Heading";
import Model3D from "./Model3D";
import Modal from "../Modal";
import Uploader from "../Uploader";
import Button from "../Button";
import ReactGA from "react-ga4";

export default function Index({
  allProducts,
  setActiveProduct,
  getNewProducts,
  uploadingProgress,
  userInfo,
  setUserInfo,
  setUploadingProgress,
  authToken,
  currentState,
  editProduct,
  setCurrentState,
  uploadFile,
  progressInfo,
  otherInfo,
  searchActive,
  handleScroll,
  divRef,
  clearAll,
  handleCancelUpload,
}) {
  const [beingRenamed, setBeingRenamed] = useState(false);
  const [showClearPopup, setShowClearPopup] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [modalIdToDelete, setModalIdToDelete] = useState("");
  const [showStopUploadingPopup, setShowUploadingPopup] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  //  const processedProducts = allProducts.filter((item) => item.processed);

  const deleteModel = () => {
    editProduct(true, modalIdToDelete);
    setShowDeletePopup(false);
  };

  const setShowModal = (index, doneFlag, closeFlag) => {
    let updatedUploadingProgress = [...uploadingProgress];
    console.log(index, doneFlag, closeFlag, "ALLFLAGGER");
    if (closeFlag) {
      updatedUploadingProgress[index] = false;
    } else if (doneFlag) {
      updatedUploadingProgress[index] = false;
    } else {
      updatedUploadingProgress[index] = "uploading";
    }
    console.log(updatedUploadingProgress, "up123");
    setUploadingProgress(updatedUploadingProgress);
  };

  //  const hideShowModal = (index) => {
  //   let updatedUploadingProgress = [...uploadingProgress]
  //   updatedUploadingProgress[index] = false
  //   setUploadingProgress(updatedUploadingProgress)
  //  }

  useEffect(() => {
    // Access and store the scroll position when the component reload
    const storedScrollPosition = localStorage.getItem("3dScrollPosition");
    if (storedScrollPosition !== null) {
      divRef.current.scrollTop = parseInt(storedScrollPosition);
    }
  }, []);

  useEffect(() => {
    if (location) {
      ReactGA.send({
        hitType: "pageview",
        page: location.pathname,
        title: "Modal Listing Page",
      });
    }
  }, [location]);

  return (
    <>
      {progressInfo.filter((item) => item).length > 0 &&
      currentState == "upload" ? (
        <>
          <section className="mt-40to100">
            <Heading
              text={`Files uploading (${
                progressInfo.filter((item) => item).length
              })`}
            />

            <div className="grid grid-cols-2 grid-rows-2 gap-16to61 lg:grid-cols-3 xl:grid-cols-4 xl:grid-rows-none">
              {progressInfo.map(
                (item, index) =>
                  item && (
                    <Model3D
                      text={otherInfo[index]?.fileName}
                      link={""}
                      uploading={true}
                      percent={item}
                      src={
                        otherInfo[index]?.preview ||
                        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQwWlgO_pdXIwmk_xfkhCvMNq27jAJ7PRTaeI7fBcc&s"
                      }
                      clicked={() => {
                        return;
                      }}
                      stopUploadingVideo={() => {
                        setShowUploadingPopup(true);
                      }}
                    />
                  )
              )}
            </div>
          </section>
        </>
      ) : null}
      {showDeletePopup ? (
        <Modal
          deleteModal
          userInfo={userInfo}
          yesClicked={deleteModel}
          noClicked={() => {
            setShowDeletePopup(false);
          }}
          setShowModal={() => {
            setShowDeletePopup(false);
          }}
          // setShowModal={(a, b, c) => setShowModal(index, b, c)}
          authToken={authToken}
          //index={index}
          // uploadFile={uploadFile}
        />
      ) : null}

      {showStopUploadingPopup ? (
        <Modal
          cancelUploading
          userInfo={userInfo}
          yesClicked={() => {
            handleCancelUpload();
            setShowUploadingPopup(false);
          }}
          noClicked={() => {
            setShowUploadingPopup(false);
          }}
          setShowModal={() => {
            setShowUploadingPopup(false);
          }}
        />
      ) : null}
      {(currentState == "model" ||
        (searchActive &&
          allProducts.filter((pitem) => pitem.processed).length > 0)) && (
        <section
          className={
            allProducts.filter((pitem) => !pitem.processed).length
              ? "mt-40to100"
              : ""
          }
        >
          <Heading
            text={`3D Models (${
              allProducts.filter((pitem) => pitem.processed).length
            })`}
          />
          <div className="grid grid-cols-2 grid-rows-2 gap-16to61 lg:grid-cols-3 xl:grid-cols-4 xl:grid-rows-none">
            {allProducts
              .filter((pitem) => pitem.processed)
              .map((item, index) => (
                <Model3D
                  text={item.product_name}
                  link={item.model_url}
                  productId={item.id}
                  editProduct={editProduct}
                  src={item.model_screenshot_url}
                  clicked={() => {
                    setActiveProduct({ ...item });
                    navigate(`/product?id=${item.id}`);
                    handleScroll();
                  }}
                  openInNewTab={() => {
                    window.open(`/product?id=${item.id}`, "_blank")?.focus();
                  }}
                  beingRenamed={beingRenamed}
                  setBeingRenamed={setBeingRenamed}
                  setShowDeletePopup={setShowDeletePopup}
                  setModalIdToDelete={setModalIdToDelete}
                />
              ))}
          </div>
        </section>
      )}

      {(currentState == "processing" ||
        (searchActive &&
          allProducts.filter((pitem) => !pitem.processed && !pitem.failed)
            .length > 0)) && (
        <section className="mt-40to100">
          <Heading
            text={`Files processing (${
              allProducts.filter((pitem) => !pitem.processed && !pitem.failed)
                .length
            })`}
          />

          <div className="grid grid-cols-2 grid-rows-2 gap-16to61 lg:grid-cols-3 xl:grid-cols-4 xl:grid-rows-none">
            {allProducts
              .filter((pitem) => !pitem.processed && !pitem.failed)
              .map((item, index) => (
                <Model3D
                  text={item.product_name}
                  allValue={item}
                  link={""}
                  uploading={true}
                  processing={true}
                  showVideImagePrev={item?.model_screenshot_url ? false : true}
                  percent={50}
                  src={
                    item?.model_screenshot_url
                      ? item.model_screenshot_url
                      : item.video_url ||
                        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQwWlgO_pdXIwmk_xfkhCvMNq27jAJ7PRTaeI7fBcc&s"
                  }
                  clicked={() => {
                    return;
                  }}
                />
              ))}
          </div>
        </section>
      )}

      {(currentState == "failed" ||
        (searchActive &&
          allProducts.filter((pitem) => pitem.failed).length > 0)) && (
        <section className="mt-40to100">
          <div className="flex justify-between">
            <Heading
              text={`Failed files (${
                allProducts.filter((pitem) => pitem.failed).length
              })`}
            />
            <Button
              outline={true}
              className="w-11/8 md:w-auto"
              text="Clear all"
              onClick={() => {
                setShowClearPopup(true);
              }}
            />
          </div>

          <div className="grid grid-cols-2 grid-rows-2 gap-16to61 lg:grid-cols-3 xl:grid-cols-4 xl:grid-rows-none">
            {allProducts
              .filter((pitem) => pitem.failed)
              .map((item, index) => (
                <Model3D
                  text={item.product_name}
                  link={""}
                  uploading={true}
                  processing={true}
                  failed={true}
                  percent={50}
                  src={
                    item.model_screenshot_url ||
                    "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQwWlgO_pdXIwmk_xfkhCvMNq27jAJ7PRTaeI7fBcc&s"
                  }
                  clicked={() => {
                    return;
                  }}
                />
              ))}
          </div>
          {showClearPopup ? (
            <Modal
              clearAllModal
              userInfo={userInfo}
              btnText="All Clear"
              heading="jsod"
              yesClicked={() => {
                clearAll();
                setShowClearPopup(false);
              }}
              noClicked={() => {
                setShowClearPopup(false);
              }}
              setShowModal={() => {
                setShowClearPopup(false);
              }}
            />
          ) : (
            <></>
          )}
        </section>
      )}
    </>
  );
}

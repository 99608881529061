import React from "react";

function Tutorials({ color, height, width, iconClassName }) {
  return (
    <svg
      className={iconClassName}
      width={width || 23}
      height={height || 16}
      viewBox="0 0 23 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="m9.398 5.2 5.6 2.8-5.6 2.8V5.2z"
        stroke={color || "#444"}
        strokeMiterlimit="22.926"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.125 1H2.875C1.839 1 1 2.01 1 3.258v9.484C1 13.989 1.84 15 2.875 15h17.25C21.161 15 22 13.989 22 12.742V3.258C22 2.011 21.16 1 20.125 1z"
        stroke={color || "#444"}
        strokeMiterlimit="22.926"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Tutorials;

import { requestWithToken } from "../helpers/helpers";

const removeWelcomePopup = async (payload) => {
  try {
    const response = await requestWithToken(
      "POST",
      `/v1/users/updateInfo`,
      payload
    );
    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const removeCreditUsedPopup = async (payload) => {
  try {
    const response = await requestWithToken(
      "POST",
      `/v1/users/creditLimitFlag`,
      payload
    );
    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const fetchUserCreditPoints = async (payload) => {
  try {
    const response = await requestWithToken(
      "POST",
      `/v1/users/creditsDetails`,
      payload
    );
    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const fetchAllCountryList = async (payload) => {
  try {
    const response = await requestWithToken(
      "POST",
      `/v1/users/countriesList`,
      payload
    );
    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

export {
  removeWelcomePopup,
  fetchUserCreditPoints,
  fetchAllCountryList,
  removeCreditUsedPopup,
};

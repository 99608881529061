import React from "react";

function OpenNewTab({ width, height, color, iconClassName, onClick }) {
  return (
    <svg
      className={iconClassName}
      onClick={onClick}
      width={width || 30}
      height={height || 30}
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
     <path d="M23 15V21.2439C23 21.7096 22.815 22.1563 22.4857 22.4857C22.1563 22.815 21.7096 23 21.2439 23H8.7561C8.29035 23 7.84368 22.815 7.51435 22.4857C7.18502 22.1563 7 21.7096 7 21.2439V8.7561C7 8.29035 7.18502 7.84368 7.51435 7.51435C7.84368 7.18502 8.29035 7 8.7561 7H15C15.1552 7 15.3041 7.06167 15.4139 7.17145C15.5237 7.28123 15.5854 7.43012 15.5854 7.58537C15.5854 7.74061 15.5237 7.8895 15.4139 7.99928C15.3041 8.10906 15.1552 8.17073 15 8.17073H8.7561C8.60085 8.17073 8.45196 8.2324 8.34218 8.34218C8.2324 8.45196 8.17073 8.60085 8.17073 8.7561V21.2439C8.17073 21.3992 8.2324 21.548 8.34218 21.6578C8.45196 21.7676 8.60085 21.8293 8.7561 21.8293H21.2439C21.3992 21.8293 21.548 21.7676 21.6578 21.6578C21.7676 21.548 21.8293 21.3992 21.8293 21.2439V15C21.8293 14.8448 21.8909 14.6959 22.0007 14.5861C22.1105 14.4763 22.2594 14.4146 22.4146 14.4146C22.5699 14.4146 22.7188 14.4763 22.8286 14.5861C22.9383 14.6959 23 14.8448 23 15Z" fill="#444444"/><path d="M23.0005 7.58537V11.3824C23.0005 11.5377 22.9388 11.6866 22.829 11.7964C22.7192 11.9061 22.5703 11.9678 22.4151 11.9678C22.2598 11.9678 22.1109 11.9061 22.0012 11.7964C21.8914 11.6866 21.8297 11.5377 21.8297 11.3824V8.99805L15.4141 15.4137C15.3031 15.5171 15.1564 15.5733 15.0047 15.5707C14.8531 15.568 14.7084 15.5066 14.6011 15.3993C14.4939 15.2921 14.4325 15.1474 14.4298 14.9957C14.4271 14.8441 14.4834 14.6973 14.5868 14.5863L21.0024 8.17073H18.618C18.4628 8.17073 18.3139 8.10906 18.2041 7.99928C18.0943 7.8895 18.0326 7.74061 18.0326 7.58537C18.0326 7.43012 18.0943 7.28123 18.2041 7.17145C18.3139 7.06167 18.4628 7 18.618 7H22.4151C22.4887 7.00059 22.5615 7.01516 22.6297 7.04293C22.7016 7.07236 22.767 7.11581 22.8219 7.17075C22.8768 7.22569 22.9203 7.29102 22.9497 7.36293C22.9812 7.433 22.9984 7.5086 23.0005 7.58537Z" fill="#444444"/>
    </svg>
  );
}

export default OpenNewTab;

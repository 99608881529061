import React from "react";

function DragDrop({ height, width, iconClassName, onClick }) {
  return (
    <svg
      onClick={onClick}
      className={iconClassName}
      width={width || 152}
      height={height || 117}
      viewBox="0 0 152 117"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M63.985 2H11.347C6.185 2 2 6.12 2 11.204v51.362c0 5.083 4.185 9.204 9.347 9.204h52.638c5.162 0 9.347-4.12 9.347-9.204V11.204C73.332 6.12 69.147 2 63.985 2z"
        stroke="#0F7358"
        strokeWidth="3"
        strokeMiterlimit="22.926"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="10 14"
      />
      <path
        d="M123.088 27.676H70.451c-5.162 0-9.347 4.12-9.347 9.203v51.363c0 5.083 4.185 9.204 9.347 9.204h52.637c5.163 0 9.347-4.121 9.347-9.204V36.879c0-5.083-4.184-9.203-9.347-9.203z"
        fill="#C8F1E1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m121.915 112.026-10.078-34.573 35.112 9.925-7.979 7.855 13.03 12.83-9.078 8.937-13.028-12.829-7.979 7.855z"
        fill="#90D7B3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M83.822 48.256 109.72 62.56 83.822 76.865v-28.61z"
        fill="#0F7358"
      />
      <path
        d="m30.157 42.025 7.51 7.397 7.511-7.397M37.666 24.346v25.06"
        stroke="#0F7358"
        strokeWidth="3"
        strokeMiterlimit="22.926"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default DragDrop;

import React from "react";

export default function Index({
  iconHeight,
  iconWidth,
  Icon,
  text,
  onClick,
  onMouseUp,
  className,
  iconClassName,
}) {
  return (
    <li
      className={`my-7 flex cursor-pointer items-center ${className} hover:bg-accent-darker`}
      onClick={onClick}
      onMouseUp={onMouseUp}
    >
      {
        <Icon
          iconClassName={`${iconClassName}`}
          height={iconHeight}
          width={iconWidth}
        />
      }
      <span className="inline">{text}</span>
    </li>
  );
}

import React from "react";
import MyfileIcon from "../../../icons/MyFile";
import ViewDetail from "./ViewDetail";

export default function Index() {
  return (
    <>
      <div className="">
        <h5 className="text-20to24 font-bold">Accessories - Details</h5>
        <ViewDetail title="Location" description="My Files/Filename" />
        <ViewDetail title="Modified on" description="19 Sept, 2022" />
        <ViewDetail title="Last Opened" description="20 Sept, 2022" />
        <ViewDetail title="Created on" description="19 Sept, 2022" />
      </div>
    </>
  );
}

import React, { useEffect, useRef, useState } from "react";
import { fetchInvoiceHistory, fetchInvoicePdf } from "../../service/modal";
import BackArrowIcon from "../../icons/BackArrow";
// import { ProgressBar } from "react-step-progress-bar";

export default function Index({ userId }) {
  const [progressStatus, setProgressStatus] = useState(0);
  const [paymentHistory, setPaymentHistory] = useState([]);
  const [downloadInvioce, setDownloadInvioce] = useState(false);
  const getPaymentHistory = async () => {
    const res = await fetchInvoiceHistory(userId);
    setPaymentHistory(res);
  };
  const dwnldbtn = useRef();

  const handleClickOutside = (event) => {
    if (dwnldbtn.current && !dwnldbtn.current.contains(event.target)) {
      setDownloadInvioce(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  useEffect(() => {
    if (userId) {
      getPaymentHistory();
    }
  }, [userId]);

  const getInvoicePdf = async (invoiceId) => {
    const res = await fetchInvoicePdf(userId, invoiceId);

    console.log("sd", res);

    // Create blob from response data
    const blob = new Blob([res], { type: "application/pdf" });

    // Create download link
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "downloaded.pdf");

    // Append link to body and click it to trigger download
    document.body.appendChild(link);
    link.click();

    // Clean up
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  };

  // useEffect(() => {
  //   dwnldbtn.downloadInvioce = setDownloadInvioce(false);
  // }, [dwnldbtn]);
  return (
    <main class="null mt-3 w-full  overflow-y-auto p-3 md:order-2 md:mt-[136px] md:min-h-unset md:pl-15 md:pr-10">
      <section className="blank-dashboard">
        <div className="pricingBlock">
          <h4 className="pricingheading mb-4">Pricing that works for you</h4>
          {/* <p className="greentxt">
            Select the number of models to be generated per month
          </p>
          <div className="stageBar">
            <div className="stageprogressBar">
              <div
                className="stageprogressBarFill"
                style={{ width: `${progressStatus}%` }}
              ></div>
            </div>
            <ProgressBar
              percent={75}
              filledBackground="linear-gradient(to right, #fefb72, #f0bb31)"
            />
            <div className="stageBarrow">
              <div
                className="stageBarBlock"
                onClick={() => {
                  setProgressStatus(12.5);
                }}
              >
                <span className="ccount">100</span>
              </div>
              <div
                className="stageBarBlock"
                onClick={() => {
                  setProgressStatus(37.5);
                }}
              >
                <span className="ccount">500</span>
              </div>
              <div className="stageBarBlock">
                <span className="ccount">1000</span>
              </div>
              <div className="stageBarBlock">
                <span className="ccount">1000+</span>
              </div>
            </div>
          </div> */}
          <div className="onDesktopview">
            <div className="stageBlockrow">
              <div
                className="stageBloc"
                // onClick={() => {
                //   setProgressStatus(12.5);
                // }}
              >
                <div
                  className={`stageBlocCard ${
                    progressStatus == 12.5 ? "active" : ""
                  }`}
                >
                  <div className="crdno">0 - 100 models</div>
                  <div className="perm">per month</div>
                  <div className="pprc">
                    <sup>$</sup> <span>8</span> <sub>/ model</sub>{" "}
                  </div>
                  <div className="acrd-fttr">
                    <ul className="fttrli">
                      <li>Billing Cycle: Monthly</li>
                      <li>Billed at month end</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div
                className="stageBloc"
                // onClick={() => {
                //   setProgressStatus(37.5);
                // }}
              >
                <div
                  className={`stageBlocCard ${
                    progressStatus == 37.5 ? "active" : ""
                  }`}
                >
                  <div className="crdno">101 - 500 models</div>
                  <div className="perm">per month</div>
                  <div className="pprc">
                    <sup>$</sup> <span>6</span> <sub>/ model</sub>{" "}
                  </div>
                  <div className="acrd-fttr">
                    <ul className="fttrli">
                      <li>Billing Cycle: Monthly</li>
                      <li>Billed at month end </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div
                className="stageBloc"
                // onClick={() => {
                //   setProgressStatus(62.5);
                // }}
              >
                <div
                  className={`stageBlocCard ${
                    progressStatus == 62.5 ? "active" : ""
                  }`}
                >
                  <div className="crdno">501 - 1000 models</div>
                  <div className="perm">per month</div>
                  <div className="pprc">
                    <sup>$</sup> <span>4</span> <sub>/ model</sub>{" "}
                  </div>
                  <div className="acrd-fttr">
                    <ul className="fttrli">
                      <li>Billing Cycle: Monthly</li>
                      <li>Billed at month end </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div
                className="stageBloc"
                // onClick={() => {
                //   setProgressStatus(100);
                // }}
              >
                <div
                  className={`stageBlocCard ${
                    progressStatus == 100 ? "active" : ""
                  }`}
                >
                  <div className="crdno">1000+ models</div>
                  <div className="perm">per month</div>
                  <div className="contdtl">
                    <p class="conttt">Contact Us</p>
                    <a href="mailto:bharath@metashopai.com">
                      bharath@metashopai.com
                    </a>
                  </div>
                  <div className="acrd-fttr">
                    <ul className="fttrli">
                      <li>Billing Cycle: Monthly</li>
                      <li>Billed at month end </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="onMobdiv">
            <div className="stageBlockrow ">
              <div
                className="stageBloc"
                // onClick={() => {
                //   setProgressStatus(12.5);
                // }}
              >
                <div
                  className={`stageBlocCard ${
                    progressStatus == 12.5 ? "active" : ""
                  }`}
                >
                  <div className="stageBlocCard_left">
                    <div className="crdno">
                      0 - 100 models<div className="perm">/ month</div>
                    </div>

                    <div className="acrd-fttr">
                      <ul className="fttrli">
                        <li>Billing Cycle: Monthly</li>
                        <li>Billed at month end</li>
                      </ul>
                    </div>
                  </div>
                  <div className="pprc">
                    <sup>$</sup> <span>8</span> <sub>/ model</sub>{" "}
                  </div>
                </div>
              </div>
              <div
                className="stageBloc"
                // onClick={() => {
                //   setProgressStatus(37.5);
                // }}
              >
                <div
                  className={`stageBlocCard ${
                    progressStatus == 37.5 ? "active" : ""
                  }`}
                >
                  <div className="stageBlocCard_left">
                    <div className="crdno">
                      0 - 100 models<div className="perm">/ month</div>
                    </div>

                    <div className="acrd-fttr">
                      <ul className="fttrli">
                        <li>Billing Cycle: Monthly</li>
                        <li>Billed at month end</li>
                      </ul>
                    </div>
                  </div>
                  <div className="pprc">
                    <sup>$</sup> <span>8</span> <sub>/ model</sub>{" "}
                  </div>
                </div>
              </div>
              <div
                className="stageBloc"
                // onClick={() => {
                //   setProgressStatus(62.5);
                // }}
              >
                <div
                  className={`stageBlocCard ${
                    progressStatus == 62.5 ? "active" : ""
                  }`}
                >
                  <div className="stageBlocCard_left">
                    <div className="crdno">
                      0 - 100 models<div className="perm">/ month</div>
                    </div>

                    <div className="acrd-fttr">
                      <ul className="fttrli">
                        <li>Billing Cycle: Monthly</li>
                        <li>Billed at month end</li>
                      </ul>
                    </div>
                  </div>
                  <div className="pprc">
                    <sup>$</sup> <span>8</span> <sub>/ model</sub>{" "}
                  </div>
                </div>
              </div>
              <div
                className="stageBloc"
                // onClick={() => {
                //   setProgressStatus(100);
                // }}
              >
                <div
                  className={`stageBlocCard ${
                    progressStatus == 100 ? "active" : ""
                  }`}
                >
                  <div className="stageBlocCard_left">
                    <div className="crdno">
                      0 - 100 models<div className="perm">/ month</div>
                    </div>

                    <div className="acrd-fttr">
                      <ul className="fttrli">
                        <li>Billing Cycle: Monthly</li>
                        <li>Billed at month end</li>
                      </ul>
                    </div>
                  </div>
                  <div className="pprc">
                    <sup>$</sup> <span>8</span> <sub>/ model</sub>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="septr"></div>
      <section className="pricingTableBlock">
        {paymentHistory.length ? (
          <>
            <div className="onDesktopview">
              <div className="pricingTableSec">
                <h4 className="pricingheading mb-4">Purchase History</h4>
                <div className="invTableBlock">
                  <table className="invTable">
                    <tr>
                      <th>Invoice Period</th>
                      <th>Invoice Number</th>
                      <th>Payment Status</th>
                      <th>Total</th>
                      <th>Actions</th>
                    </tr>
                    {paymentHistory &&
                      paymentHistory?.map((value) => {
                        return (
                          <tr>
                            <td>{value?.billerMonth}</td>
                            <td>{value?.billingNo}</td>
                            <td>
                              {value?.status ? (
                                <div className="statusBtnBox success">
                                  <span>Paid</span>
                                </div>
                              ) : (
                                <div className="statusBtnBox danger">
                                  <span>Pending</span>
                                </div>
                              )}
                            </td>
                            <td>{value?.amount}</td>
                            <td>
                              <div
                                className="actionBtngrp"
                                onClick={() => {
                                  getInvoicePdf(value._id);
                                }}
                              >
                                <button className="actionBtn">Download</button>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                  </table>
                </div>
              </div>
            </div>
            <div className="onMobdiv">
              <div className="pricingTableSec mb-8">
                <h4 className="pricingheading mb-4">Purchase History</h4>
                {paymentHistory &&
                  paymentHistory.map((value) => {
                    return (
                      <div className="prc_card">
                        <div className="prc_left">
                          <div className="prc_date">23</div>
                          <div className="prc_date">{value?.billerMonth}</div>
                          <div className="prc_mdl">{value?.billingNo}</div>
                        </div>
                        <div className="prc_right">
                          <div className="prc_right-content">
                            <div className="prc_amount">$ {value?.amount}</div>
                            {value?.status ? (
                              <div className="prc_status paid">Paid</div>
                            ) : (
                              <div className="prc_status pending">Pending</div>
                            )}
                          </div>
                          <div className="threeDots">
                            <div
                              className="threeDots_btn"
                              onClick={() => {
                                getInvoicePdf(value._id);
                              }}
                            >
                              <img src="/images/dwnldInv.svg" alt="" />
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </>
        ) : (
          <></>
        )}
      </section>
    </main>
  );
}

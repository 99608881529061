import React, { useState } from "react";
// import NewUser from "../../pages/NewUser";

export default function Index({ children }) {
  let newUser = false;
  return (
    <main
      className={`${
        newUser ? "flex items-center justify-center" : null
      } mt-3 w-full  overflow-y-auto p-3 md:order-2 md:mt-[136px] md:min-h-unset md:pl-15 md:pr-10`}
    >
      {children}
    </main>
  );
}

import React from "react";

function backArrow({ height, width, color, iconClassName, onClick }) {
  return (
    <svg
      onClick={onClick}
      className={`cursor-pointer ${iconClassName}`}
      width={width || "14"}
      height={height || "12"}
      viewBox="0 0 14 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 1 1 6l5 5M1 6h12"
        stroke={color || "#137359"}
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default backArrow;

import axios from "axios";
import config from "../config.json";

export const requestWithoutToken = async (method, url, body) => {
  let request;
  url = config.backend_url + url;

  switch (method) {
    case "GET":
      request = await axios.get(url);
      break;
    case "POST":
      request = await axios.post(url, body);
      break;
    default:
      break;
  }

  return request;
};

export const requestWithToken = async (method, url, body, responseType) => {
  let request;
  url = config.backend_url + url;

  const headers = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };
  if (responseType) {
    headers[`responseType`] = responseType;
  }
  switch (method) {
    case "GET":
      request = await axios.get(url, headers);
      break;
    case "POST":
      request = await axios.post(url, body, headers);
      break;
    case "PUT":
      request = await axios.put(url, body, headers);
      break;
    case "DELETE":
      request = await axios.delete(url, {
        data: body,
        headers: headers.headers,
      });
      break;

    default:
      break;
  }

  return request;
};

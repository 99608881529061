import React from "react";

function ViewAR({ color, height, width, iconClassName }) {
  return (
    <svg
      className={iconClassName}
      width={width || 26}
      height={height || 26}
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="m19.926 8.007-6.17-3.664A1.584 1.584 0 0 0 13 4.137c-.252 0-.529.077-.755.206L6.074 8.007c-.479.284-.756.8-.756 1.342v7.303c0 .541.277 1.058.756 1.341l6.17 3.665c.227.129.504.206.756.206s.53-.077.756-.207l6.17-3.664c.479-.283.756-.8.756-1.341V9.349c0-.568-.277-1.084-.756-1.342zM13 5.685l5.44 3.2L13 12.11 7.585 8.885 13 5.685zm-6.17 4.542 5.415 3.225v6.425L6.83 16.652v-6.426zm6.926 9.65v-6.425l5.415-3.2v6.425l-5.415 3.2zM4.588 0h3.059v1.53H4.588A3.068 3.068 0 0 0 1.53 4.588v3.06H0v-3.06A4.582 4.582 0 0 1 4.588 0zM1.53 21.412a3.068 3.068 0 0 0 3.06 3.059h3.058V26H4.588A4.582 4.582 0 0 1 0 21.412v-3.06h1.53v3.06zM21.412 0A4.582 4.582 0 0 1 26 4.588v3.06h-1.53v-3.06a3.068 3.068 0 0 0-3.058-3.058h-3.059V0h3.059zM26 21.412A4.582 4.582 0 0 1 21.412 26h-3.059v-1.53h3.059a3.068 3.068 0 0 0 3.058-3.058v-3.06H26v3.06z"
        fill={color || "#fff"}
      />
    </svg>
  );
}

export default ViewAR;

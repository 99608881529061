import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import Input from "../../components/Input";
import Button from "../../components/Button";
import countryList from "react-select-country-list";
import { countryCodes } from "../../country";
import axios from "axios";
import config from "../../config.json";
import Heading from "../../components/Heading";
import { fetchAllCountryList } from "../../service/home";
import { useAuth0 } from "@auth0/auth0-react";

export default function Index({
  setUserInfo,
  userInfo,
  authToken,
  setIsLoading,
}) {
  const navigate = useNavigate();
  const { logout } = useAuth0();

  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companySite, setCompanySite] = useState("");
  const [address, setAddress] = useState("");
  const [country, setCountry] = useState("");
  const [taxId, setTaxId] = useState("");
  const [allCountry, setAllCountry] = useState([]);

  const changeHandler = (value, setValue) => {
    setValue(value);
  };

  const submitDetails = () => {
    setIsLoading(true);
    axios
      .post(
        `${config.backend_url}/v1/users/update-user?userId=${userInfo.id}`,
        {
          name: name,
          phone: phone,
          countryCode: countryCode,
          companyName: companyName,
          companySite: companySite,
          address,
          taxId,
          country,
        },
        {
          headers: { Authorization: `Bearer ${authToken}` },
        }
      )
      .then((response) => {
        setUserInfo(response.data);
        setIsLoading(false);
        navigate("/profile");
      });
  };

  const getAllCountryList = async () => {
    const res = await fetchAllCountryList({});

    if (res.length) {
      setAllCountry(res);
    }
  };

  useEffect(() => {
    getAllCountryList();
    if (userInfo) {
      setName(userInfo.name);
      setPhone(userInfo?.phone);
      setCompanyName(userInfo?.companyName);
      setCompanySite(userInfo?.companySite);
      setAddress(userInfo?.address);
      setCountry(userInfo?.country);
      setTaxId(userInfo?.taxId);
    }
    console.log("JODJOOIJDF", userInfo);
  }, [userInfo]);

  return (
    <>
      <main class="null mt-3 w-full  overflow-y-auto p-3 md:order-2 md:mt-[136px] md:min-h-unset md:pl-15 md:pr-10">
        <section className="profilesec">
          <div className="profilesecBlock w-full md:w-1/3">
            <Heading text="My Account" />
            {/* <div className="absolute -top-17 left-1/2 mx-auto h-100to200 w-100to200 -translate-x-1/2 overflow-hidden lg:static lg:translate-x-0">
         
          </div> */}
            {/* <div className="flex w-full items-center justify-center ">
              <div
                className="text-	lg:h-47 lg:w-27 flex h-24 w-24 items-center justify-center rounded-full bg-accent-darker p-3 ring-2 ring-accent"
                alt="user-profile"
              >
                <span className="font-semibold">
                  {name
                    .split(" ")
                    .map((n) => n[0])
                    .join("  ")
                    .toUpperCase()}
                </span>
              </div>
            </div> */}
            <Input
              type="text"
              label="Name"
              id="userProfile-name"
              className="my-18to24 mt-10"
              onChange={(e) => changeHandler(e.target.value, setName)}
              value={name}
            />

            <div className="my-18to24 flex gap-4">
              <Input
                type="select-country"
                label="Country"
                className="grow"
                id="userProfile-countryCode"
                options={countryCodes}
                onChange={(e) => changeHandler(e.target.value, setCountryCode)}
                value={countryCode}
              />
              <Input
                type="number"
                label="Phone Number"
                className="grow-4"
                onChange={(e) => changeHandler(e.target.value, setPhone)}
                value={phone}
              />
            </div>

            <Input
              type="text"
              label="Email Address"
              id="userProfile-companyEmail"
              className="my-18to24"
              onChange={(e) => changeHandler(e.target.value, setEmail)}
              value={userInfo?.email}
              disabled={true}
            />

            <Input
              type="text"
              label="Company Name"
              id="userProfile-companyName"
              className="my-18to24"
              onChange={(e) => changeHandler(e.target.value, setCompanyName)}
              value={companyName}
            />

            <Input
              type="text"
              label="Billing Address"
              id="userProfile-address"
              className="my-18to24"
              onChange={(e) => changeHandler(e.target.value, setAddress)}
              value={address}
            />

            {/* <Input
              type="select"
              label="Issuing Country"
              id="userProfile-companyName"
              className="my-18to24"
              options={allCountry}
              onChange={(e) => changeHandler(e.target.value, setCountry)}
              value={country}
            /> */}

            <div className={`relative my-18to24`}>
              <select
                type="text"
                id="countries"
                className="border-1 peer block h-36to56 w-full appearance-none rounded-xl border-border bg-transparent px-2.5 pl-5 text-sm text-gray-900 focus:border-primary focus:outline-none focus:ring-0"
                placeholder=" "
                value={country}
                onChange={(e) => changeHandler(e.target.value, setCountry)}
              >
                <option selected>Choose a country</option>
                {allCountry?.map((value) => {
                  return <option value={value._id}>{value.name}</option>;
                })}
              </select>
              <label
                htmlFor={"countries"}
                className="absolute left-3 top-2 z-10 origin-[0] -translate-y-4 scale-75 transform bg-white px-2 text-sm text-black duration-300 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-focus:top-2 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:px-2 peer-focus:text-primary"
              >
                Select Country
              </label>
            </div>

            <Input
              type="text"
              label="Tax ID"
              id="userProfile-tax"
              className="my-18to24"
              onChange={(e) => changeHandler(e.target.value, setTaxId)}
              value={taxId}
            />

            <Input
              type="text"
              label="Company Website"
              id="userProfile-companyWebsite"
              className="my-18to24"
              onChange={(e) => changeHandler(e.target.value, setCompanySite)}
              value={companySite}
            />
            <div className="flex gap-15to22 text-center">
              <Button
                text={userInfo?.newUser ? "Submit" : "Update"}
                onClick={submitDetails}
              />
              <Button
                outline
                text="Logout"
                onClick={() => {
                  localStorage.clear();
                  logout({ returnTo: window.location.origin });
                }}
              />
            </div>

            {/* <button
              className="absolute left-1/2 mt-28 block -translate-x-1/2 border-b border-primary text-center text-primary lg:relative lg:mt-14"
              onClick={() => navigate("/profile")}
            >
              Go Back
            </button> */}
          </div>
        </section>
      </main>
    </>
  );
}

import React from "react";

function DropBox({ height, width, onClick, iconClassName }) {
  return (
    <svg
      className={iconClassName}
      onClick={onClick}
      width={width || "70"}
      height={height || "70"}
      viewBox="0 0 70 70"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#2ro2rm077a)">
        <rect x="6" y="6" width="58" height="58" rx="14" fill="#FEFEFE" />
      </g>
      <path
        d="m27.427 17.863-10.881 7.028 7.573 6.074L35 24.197l-7.573-6.334zM16.546 36.865l10.881 7.115L35 37.646l-10.88-6.681-7.574 5.9zM35 37.646l7.66 6.334 10.795-7.028-7.486-5.987L35 37.646zm18.455-12.755-10.794-7.028L35 24.197l10.968 6.768 7.486-6.074zM35.088 39.034l-7.66 6.334-3.222-2.169v2.43l10.881 6.507 10.882-6.508V43.2l-3.308 2.17-7.574-6.335z"
        fill="#007EE5"
      />
      <defs>
        <filter
          id="2ro2rm077a"
          x="0"
          y="0"
          width="70"
          height="70"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="2" dy="2" />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix values="0 0 0 0 0.0745098 0 0 0 0 0.45098 0 0 0 0 0.34902 0 0 0 0.1 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_654_1250"
          />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-2" dy="-2" />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix values="0 0 0 0 0.0745098 0 0 0 0 0.45098 0 0 0 0 0.34902 0 0 0 0.1 0" />
          <feBlend
            in2="effect1_dropShadow_654_1250"
            result="effect2_dropShadow_654_1250"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect2_dropShadow_654_1250"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}

export default DropBox;

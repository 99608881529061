import React from "react";
import Input from "../../../Input";

export default function Index({ text }) {
  return (
    <div className="my-27to56 ml-9 first:mt-8">
      <Input type="checkbox" label={text} id="checkme" />
    </div>
  );
}

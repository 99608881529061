import React, { useEffect } from "react";
import Sad from "../../icons/Burgermenu";

export default function Index({
  type,
  options,
  id,
  label,
  success,
  error,
  successMessage,
  errorMessage,
  className,
  placeholder,
  onChange,
  value,
  disabled,
}) {
  useEffect(() => {
    console.log("JOIDJF", value);
  }, [id]);
  if (type === "checkbox") {
    return (
      <div className={`relative ${className}`}>
        <input
          type="checkbox"
          className="h-5 w-5 appearance-none rounded border-primary bg-accent-lighter pl-5 checked:bg-primary indeterminate:bg-primary focus:ring-0 checked:focus:bg-primary checked:hover:bg-primary"
          id="temp"
        />
        <label forhtml="temp" className="ml-3">
          {label}
        </label>
      </div>
    );
  } else if (type === "select") {
    return (
      <div className={`relative ${className}`}>
        <select
          type="text"
          id={id}
          className="border-1 peer block h-36to56 w-full appearance-none rounded-xl border-border bg-transparent px-2.5 pl-5 text-sm text-gray-900 focus:border-primary focus:outline-none focus:ring-0"
          placeholder=" "
        >
          {options.map((option, item) => {
            return <option value="">{option}</option>;
          })}
        </select>
        <label
          htmlFor={id}
          className="absolute left-3 top-2 z-10 origin-[0] -translate-y-4 scale-75 transform bg-white px-2 text-sm text-black duration-300 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-focus:top-2 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:px-2 peer-focus:text-primary"
        >
          {label}
        </label>
      </div>
    );
  } else if (type === "select-country") {
    return (
      <div className={`relative ${className}`}>
        <select
          type="text"
          id={id}
          className="border-1 peer block h-36to56 w-full appearance-none rounded-xl border-border bg-transparent px-2.5 pl-5 text-sm text-gray-900 focus:border-primary focus:outline-none focus:ring-0"
          placeholder=" "
        >
          {options.map((option, item) => {
            return (
              <option value={option?.code}>
                <div>
                  <span className="px-4">{option?.sym}</span>
                  <span className="px-4">{option?.code}</span>
                </div>
              </option>
            );
          })}
        </select>
        <label
          htmlFor={id}
          className="absolute left-3 top-2 z-10 origin-[0] -translate-y-4 scale-75 transform bg-white px-2 text-sm text-black duration-300 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-focus:top-2 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:px-2 peer-focus:text-primary"
        >
          {label}
        </label>
      </div>
    );
  }
  {
    return (
      <>
        <div className={`relative ${className}`}>
          <input
            type={type || "text"}
            pattern={type == "number" ? "[0-9]{3}-[0-9]{2}-[0-9]{3}" : false}
            id={id}
            className={`border-1 peer block h-36to56 w-full appearance-none rounded-xl bg-transparent px-2.5 pb-4 pl-5 pt-5 text-sm text-gray-900 focus:outline-none focus:ring-0 ${
              success
                ? "border-success focus:border-success"
                : error
                ? "border-error focus:border-error"
                : "border-border focus:border-primary"
            }`}
            placeholder={placeholder || " "}
            onChange={onChange}
            value={value}
            disabled={disabled}
          />
          <label
            htmlFor={id}
            className={`absolute left-3 top-2 z-10 origin-[0] -translate-y-4 scale-75 transform bg-accent-lighter px-2 text-sm duration-300 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-focus:top-2 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:px-2  ${
              success
                ? "text-success peer-focus:text-success"
                : error
                ? "text-error peer-focus:text-error"
                : "text-black peer-focus:text-primary"
            }`}
          >
            {label}
          </label>
        </div>
        {(success || error) && (
          <p
            className={`ml-2 mt-2 text-xs ${
              success ? "text-success" : "text-error"
            }`}
          >
            {success && successMessage}
            {error && errorMessage}
          </p>
        )}
      </>
    );
  }
}

import React, { useEffect, useState } from "react";
import Tutorial from "./YoutubeTutorial";
import Chevron from "../../icons/Chevron";
import { points, image, mainHeading, bodyFontSize } from "../../data/tutorials";
import ReactGA from "react-ga4";
import { useLocation } from "react-router-dom";
import { fetchPendingBills } from "../../service/modal";

export default function Index({ token, userId, setShowPaymentModal }) {
  const [tutDetails, setTutDetails] = useState(true);
  const location = useLocation();
  const [pendingData, setPendingData] = useState([]);

  const getPendingBill = async () => {
    const res = await fetchPendingBills(token, userId);
    if (res) {
      setPendingData(res);
    }
  };
  useEffect(() => {
    if (userId) {
      getPendingBill();
    }

    // console.log("asdasasfdsgGSD");
  }, [userId, token]);

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname,
      title: "Tutorials Page",
    });
  }, []);

  return (
    <main className="mt-3 w-full overflow-y-auto p-3 md:order-2 md:mt-[136px] md:min-h-unset md:pl-15 md:pr-10">
      {pendingData.length ? (
        <div className="paymentPendingBox v2 mb-3">
          <div className="Pending-title v2">
            <img
              className="warning-mark"
              src="./images/alert-circle.svg"
              alt="warning"
            />
            Payment Pending:
          </div>
          <div className="Pending-text">
            Please pay the outstanding amount to continue creating 3D models
          </div>
          <div
            className="payBtn"
            onClick={() => {
              setShowPaymentModal(true);
            }}
          >
            Pay Now
          </div>
        </div>
      ) : (
        <></>
      )}

      <section className="mb-20to40 rounded-20to30 border border-stream p-20to50">
        <div className={`${tutDetails && "mb-20to36"} flex justify-between`}>
          <h3 className="text-18to34 font-medium">{mainHeading}</h3>
          <Chevron
            onClick={() => {
              setTutDetails(!tutDetails);
            }}
            iconClassName={`${
              tutDetails ? "rotate-180" : "rotate-0"
            } cursor-pointer h-28to36 w-28to36 p-2 bg-accent-darker rounded-full text-primary font-bold`}
          />
        </div>

        <div
          className={`${
            tutDetails
              ? "align-items-center lg:flex lg:justify-between"
              : "hidden"
          } `}
        >
          <div>
            {points.map((item, index) => {
              return (
                <div
                  key={index}
                  className={`my-20to30 first:mt-0 last:lg:mb-0 text-${bodyFontSize}`}
                >
                  <h5>{item.heading}</h5>
                  <ul className="list-disc pl-5">
                    {Object.values(item.point).map((dot, index) => {
                      return <li key={index}> {dot}</li>;
                    })}
                  </ul>
                </div>
              );
            })}
          </div>
          {/* <picture>
            <source srcSet={image.desktop} />
            <img src={image.mobile} alt="3d modal" />
          </picture> */}
          <video id="bannerVideo" controls className="tutorialfrontvdo">
            <source src="/videos/banner.mp4" type="video/mp4" />
          </video>
        </div>
      </section>
      <section className="grid gap-5 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
        {/*<Tutorial
          text="Tips on how to click a video for a perfect 3D model"
          title="video title will be here"
          url="https://www.youtube.com/embed/r-b5afF-9Gs"
        />

        <Tutorial
          text="Tips on how to click a video for a perfect 3D model"
          title="video title will be here"
          url="https://www.youtube-nocookie.com/embed/R6YJcspGSRs"
        />

        <Tutorial
          text="Tips on how to click a video for a perfect 3D model"
          title="video title will be here"
          url="https://youtube.com/embed/nca6ueq1ko4"
          />*/}

        <Tutorial
          text="Sample Video Guideline - V1.0"
          title="video title will be here"
          url="https://youtube.com/embed/SM4omFIkzeI"
        />
      </section>
    </main>
  );
}

import React from "react";
import CropIcon from "../../../icons/Crop";
import DeleteIcon from "../../../icons/Delete";
import UndoIcon from "../../../icons/Undo";
import RedoIcon from "../../../icons/Redo";
import EditOption from "../EditOption";
import Button from "../../../components/Button";

export default function Index() {
  return (
    <div className="absolute flex w-full justify-center md:bottom-10">
      <div className="flex h-full w-full justify-center md:mr-5 md:mt-3 3xl:mr-unset">
        <EditOption
          Icon={CropIcon}
          textClassName="mt-0.5"
          className="lg:mx-3 3xl:mx-7"
        />
        <EditOption Icon={UndoIcon} className="lg:mx-3 3xl:mx-7" />
        <EditOption Icon={RedoIcon} className="lg:mx-3 3xl:mx-7" />
        <EditOption Icon={DeleteIcon} className="lg:mx-3 3xl:mx-7" />
      </div>

      <div className="absolute flex lg:right-10 3xl:right-20">
        <Button
          text="Save"
          className="border-0 font-semibold md:mr-8 md:border md:font-normal lg:mr-2 3xl:mr-4"
        />
        <Button
          outline
          text="Cancel"
          className="border-0 font-semibold md:order-2 md:border md:font-normal"
        />
      </div>
    </div>
  );
}

import React from "react";
import CropIcon from "../../../icons/Crop";
import DeleteIcon from "../../../icons/Delete";
import UndoIcon from "../../../icons/Undo";
import RedoIcon from "../../../icons/Redo";
import EditOption from "../EditOption";
import Button from "../../../components/Button";

export default function Index({ className }) {
  return (
    <div className="absolute bottom-0 z-20 w-full bg-white py-4 shadow-base md:hidden">
      <div className="grid w-full grid-cols-4 text-center">
        <EditOption text="Crop" Icon={CropIcon} textClassName="mt-0.5" />
        <EditOption text="Undo" Icon={UndoIcon} />
        <EditOption text="Redo" Icon={RedoIcon} />
        <EditOption text="Delete" Icon={DeleteIcon} />
      </div>
      <div className="mt-4 grid w-full grid-cols-2 gap-3 px-3">
        <Button text="Save" className="w-full" />
        <Button outline text="Cancel" className="w-full" />
      </div>
    </div>
  );
}

import React, { useState } from "react";
import Button from "../../Button";
import { useLocation, useNavigate } from "react-router-dom";

export default function Index({ uploadFile }) {
  const location = useLocation();
  const navigate = useNavigate();
  const [file, setFile] = useState(null);
  //  const [fileNameEntered, setFileNameEntered] = useState("");
  const [error, setError] = useState("");

  const onFileChange = (e) => {
    const file = e.target.files[0];
    const fileNameNoSpaces = file.name.replace(/ /g, "");
    const newFile = new File([file], fileNameNoSpaces);
    // console.log(newFile)
    setFile(newFile);
    setError("");

    if (location.pathname == "/tutorials") {
      navigate("/");
    }
  };

  const b64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  };

  const onSubmit = async () => {
    // if (!fileNameEntered) {
    //   setError("Please enter a name for video");
    //   return;
    // }
    if (!file) {
      setError("Please choose a file");
      return;
    } else {
      setError("");
    }

    let fileNameEntered = Math.random().toString(36).substring(2, 8);
    fileNameEntered = file.name + "-" + fileNameEntered;
    if (file) {
      uploadFile(file, fileNameEntered);
    }
  };

  console.log(file, "filees");
  return (
    <div className="vidbbx flex w-full flex-col justify-between rounded-3xl">
      <div>
        <h5 className="text-20to24 font-semibold">
          <Button
            link
            fileUpload
            text="Choose a video"
            onChange={onFileChange}
          />
          {/* <span className="">
            Choose a video
          </span> */}
          <span> to upload</span>
        </h5>
        <span className="mt-2"> {file ? file.name : null}</span>
        <p className="text-14to18 mb-2 mt-14to30">
          Supported formats: MP4 and MOV only
        </p>
        {error ? <span className="mt-4 text-error">{error}</span> : null}
      </div>
      <Button text="Upload" className="block w-full" onClick={onSubmit} />

      <p className="mt-5">How to record a video:</p>

      <video id="bannerVideo" controls autoPlay>
        <source src="/videos/banner.mp4" type="video/mp4" />
      </video>
      <p className="text-14to18 mt-14to30 mt-3 font-bold">
        Tip:{" "}
        <span className="font-light">
          Don’t move/rotate object while recording. For more tips visit our
          guidelines for better 3D asset creation.
        </span>
      </p>
      {/* <div className="p-6">
        <Button
          text="Select video"
          Icon={UploadIcon}
          fileUpload={true}
          onChange={onFileChange}
        />
        <span> {file ? file.name : null}</span>
      </div>

      <form className="w-1/2">
        <div className="relative">
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3 lg:pl-5"></div>
          <input
            type="search"
            id="default-search"
            className="block w-full rounded-2xl border-0 bg-white p-4 pl-10 text-sm text-[#999] focus:ring-primary"
            placeholder="Enter video's name"
            value={fileNameEntered}
            onChange={(e) => {
              setFileNameEntered(e.target.value);
            }}
            //onClick={openSearchbar}
          />
        </div>
      </form>
      <div className="pt-6">
        <Button text="Upload" className="p-4" onClick={onSubmit} />
        {error ? (
          <div className="text-s font-semiBold text-red-700">{error}</div>
        ) : null}
      </div>
      <div className="upload-actions mt-4 flex w-4/5 justify-between md:mt-8 md:w-1/2"></div> */}
    </div>
  );
}

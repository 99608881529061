import React from "react";

function GoogleDrive({ height, width, onClick, iconClassName }) {
  return (
    <svg
      className={iconClassName}
      onClick={onClick}
      width={width || "70"}
      height={height || "70"}
      viewBox="0 0 70 70"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#aey15vu7ya)">
        <rect x="6" y="6" width="58" height="58" rx="14" fill="#FEFEFE" />
      </g>
      <path
        d="m20.455 45.355 1.511 2.473c.314.52.766.93 1.296 1.227l5.398-8.849H17.864c0 .576.157 1.153.47 1.673l2.12 3.476z"
        fill="#0066DA"
      />
      <path
        d="m35 29.795-5.398-8.849c-.53.298-.981.707-1.295 1.227l-9.972 16.36a3.233 3.233 0 0 0-.471 1.672H28.66L35 29.795z"
        fill="#00AC47"
      />
      <path
        d="M46.738 49.055c.53-.298.981-.707 1.295-1.227l.628-1.023 3.004-4.926c.314-.52.471-1.097.471-1.673H41.339l2.297 4.276 3.102 4.573z"
        fill="#EA4335"
      />
      <path
        d="m35.002 29.795 5.398-8.849a3.6 3.6 0 0 0-1.766-.446H31.37c-.628 0-1.236.167-1.766.446l5.398 8.849z"
        fill="#00832D"
      />
      <path
        d="M41.342 40.206H28.661l-5.399 8.849a3.6 3.6 0 0 0 1.767.446h19.945c.628 0 1.236-.167 1.766-.446l-5.398-8.849z"
        fill="#2684FC"
      />
      <path
        d="m46.68 30.353-4.986-8.18a3.477 3.477 0 0 0-1.296-1.227L35 29.795l6.34 10.41h10.778c0-.576-.157-1.152-.471-1.673l-4.967-8.18z"
        fill="#FFBA00"
      />
      <defs>
        <filter
          id="aey15vu7ya"
          x="0"
          y="0"
          width="70"
          height="70"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="2" dy="2" />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix values="0 0 0 0 0.0745098 0 0 0 0 0.45098 0 0 0 0 0.34902 0 0 0 0.1 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_654_1159"
          />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-2" dy="-2" />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix values="0 0 0 0 0.0745098 0 0 0 0 0.45098 0 0 0 0 0.34902 0 0 0 0.1 0" />
          <feBlend
            in2="effect1_dropShadow_654_1159"
            result="effect2_dropShadow_654_1159"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect2_dropShadow_654_1159"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}

export default GoogleDrive;

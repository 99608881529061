import React from "react";

export default function Index({
  text,
  Icon,
  iconColor,
  iconHeight,
  iconWidth,
  className,
  iconClassName,
  onClick,
  tag,
}) {
  const CustomTag = tag === "button" ? "button" : "div";
  return (
    <CustomTag
      className={`menu my-14to50 flex justify-between rounded-xl bg-white p-4 text-base hover:bg-accent-darker md:justify-center lg:h-16 lg:w-16 lg:items-center lg:bg-transparent ${className}`}
      onClick={onClick}
    >
      <h5 className="md:hidden">{text}</h5>
      <Icon
        color={iconColor}
        width={iconWidth}
        height={iconHeight}
        iconClassName={iconClassName}
      />
    </CustomTag>
  );
}

import React, { useRef } from "react";
import ViewDetailsIcon from "../../icons/ViewDetails";
import DeleteIcon from "../../icons/Delete";
import PencilIcon from "../../icons/Pencil";
import MoveToIcon from "../../icons/MoveTo";
import CloseIcon from "../../icons/Close";
import DuplicateIcon from "../../icons/Duplicate";
import FileAction from "./FileAction";
import clickedOutside from "../../utils/clickedOutside";
import OpenNewTab from "../../icons/OpenNewTab";

export default function Index({
  onClick,
  onMouseUp,
  setMenu,
  onRename,
  onDelete,
  onOpenInNewTab,
}) {
  const closeMenu = () => {
    setMenu(false);
  };

  const wrapperRef = useRef(null);
  clickedOutside(wrapperRef, closeMenu);
  return (
    <div
      ref={wrapperRef}
      className="fixed left-0 top-0 z-20 h-screen w-screen bg-transparent-7 xl:static xl:h-unset xl:w-max"
      onClick={onClick}
    >
      <ul className="file-actions fixed bottom-0 left-0 z-10 w-full rounded-t-5 bg-white px-5 shadow-2xl xl:absolute xl:left-unset xl:top-0 xl:h-max xl:w-max xl:min-w-[200px] xl:rounded-5">
        {/* <FileAction
          onMouseUp={onMouseUp}
          Icon={MoveToIcon}
          text="Move To"
          onClick={() => {
            console.log("moveto clicked");
          }}
        /> */}

        {/* <FileAction
          onMouseUp={onMouseUp}
          Icon={DuplicateIcon}
          text="Duplicate"
          onClick={() => {
            console.log("Duplicate clicked");
          }}
        /> */}
        <CloseIcon
          iconClassName="absolute right-6 top-6 xl:hidden"
          onClick={closeMenu}
        />
        <FileAction
          iconClassName="mr-1"
          onMouseUp={onMouseUp}
          Icon={OpenNewTab}
          text="Open in new tab"
          onClick={onOpenInNewTab}
        />
        <FileAction
          iconClassName="mr-1"
          onMouseUp={onMouseUp}
          Icon={PencilIcon}
          text="Rename"
          onClick={onRename}
        />
        <FileAction
          iconClassName="mr-1"
          onMouseUp={onMouseUp}
          Icon={DeleteIcon}
          text="Delete"
          onClick={onDelete}
        />
        {/* <FileAction
          Icon={ViewDetailsIcon}
          text="View Details"
          className="md:hidden"
          onClick={() => {
            console.log("View Detailo clicked");
          }}
        /> */}
      </ul>
    </div>
  );
}

import React from "react";
import BackArrowIcon from "../../icons/BackArrow";
// import RecentSearch from "./RecentSearch";
import SearchTag from "./SearchTag";
import SearchTitle from "./SearchTitle";

export default function Index({ setOpenSearch, searchItOut }) {
  const closeSearch = () => {
    searchItOut()
    setOpenSearch(false);
  };

  return (
    <div className="fixed top-0 z-30 p-3 border border-primary  w-screen bg-white px-2.5 md:absolute md:left-[60px] md:top-[120px] md:h-max md:max-w-[400px] md:rounded-2xl md:p-5 md:shadow-xl">
      <div className="flex items-center md:hidden">
        <BackArrowIcon height="12" width="14" onClick={closeSearch} />
        <input
          type="text"
          className="ml-2 w-full border-0 focus:border-0 focus:ring-0"
          placeholder="Search..."
          onChange={(e) => {
            searchItOut(e)
          }}
        />
      </div>

      {/* <div className="mt-4">
        <SearchTitle text="Recent Searches" />
        <div className="flex flex-wrap">
          <SearchTag text="Shoes" className="m-1.5" />
          <SearchTag text="Casual" className="m-1.5" />
          <SearchTag text="SKUI7765" className="m-1.5" />
          <SearchTag text="Metaverse" className="m-1.5" />
          <SearchTag text="Metaverse" className="m-1.5" />
          <SearchTag text="bag" className="m-1.5" />
          <SearchTag text="table" className="m-1.5" />
          <SearchTag text="laptop" className="m-1.5" />
          <SearchTag text="Wallet" className="m-1.5" />
        </div>
      </div>

      <div className="mt-4">
        <SearchTitle text="All Others" />
        <div className="group">
          <SearchTag hover text="Puma Casual" className="my-4" />
          <SearchTag text="Shoes Casual" className="my-4" />
          <SearchTag hover text="Formal Shoes" className="my-4" />
          <SearchTag hover text="brand Casual" className="my-4" />
        </div>
      </div> */}
    </div>
  );
}

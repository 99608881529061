import React from "react";

function MyAccount({ height, width, iconClassName, onClick, color }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || "20"}
      height={height || "20"}
      viewBox="0 0 20 20"
      fill="none"
      onClick={onClick}
      className={iconClassName}
    >
      <path
        d="M16 17v-1.333c0-.708-.316-1.386-.879-1.886C14.56 13.281 13.796 13 13 13H7c-.796 0-1.559.281-2.121.781-.563.5-.879 1.178-.879 1.886V17M10 10a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"
        stroke={color || "#444"}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default MyAccount;

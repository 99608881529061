const mainHeading = "Video Recording Guidelines for Version 1.1";
const bodyFontSize = "14to16"; //Use "14to16" or "16to20"
const points = [
  {
    heading: "Surrounding",
    point: {
      a: "Ensure the subject is well-lit with white light and isolated from the background.",
      b: "Avoid locations with inconsistent exposure changes.",
    },
  },
  {
    heading: "Recording",
    point: {
      a: "Use any landscape or portrait mode and set a minimum quality of 1080p for recording.",
      b: "Ensure the subject occupies at least 80% of the screen.",
      c: "Do not move/rotate the subject. Keep it stationary.",
      d: "Maintain a consistent distance between the camera and the subject.",
    },
  },
  {
    heading: "Angles",
    point: {
      a: "Capture a complete 360-degree view of the subject from the top, center, and lower angles.",
    },
  },
  // {
  //   heading: "File name",
  //   point: {
  //     a: "Remove blank spaces from the filename before uploading.",
  //   },
  // },
];
const image = {
  mobile: "./images/tutorials-modal-mobile.webp ",
  desktop: "./images/tutorials-modal-desktop.webp",
};
module.exports = { points, image, mainHeading, bodyFontSize };

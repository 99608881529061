import './loader.css'

export default function Index() {
    return (
        <div className="fixed left-0 top-0 z-40 h-screen w-screen bg-transparent-7 transition">
<span className="loader"></span>
</div>
    )

}




import React from "react";
import DropBoxIcon from "../../../icons/DropBox";
import GoogleDriveIcon from "../../../icons/GoogleDrive";
import DragDropIcon from "../../../icons/DragDrop";

export default function Index() {
  return (
    <div
      className="flex h-full w-full flex-col items-center justify-center rounded-3xl border-dashed border-stream text-center md:border-2
        lg:p-15"
    >
      <DragDropIcon iconClassName="hidden md:block" />
      <h5 className="my-5 hidden text-xl md:block">
        Take atleast 1-2 min video
      </h5>

      <h2 className="hidden text-3xl font-medium md:block">
        Drop video here or <span className="text-primary">browse</span>
        <br /> or import from:
      </h2>
      <h5 className="text-lg font-semibold md:hidden">Upload a video</h5>
      <div className="upload-actions mt-4 flex w-4/5 justify-between md:mt-8 md:w-1/2">
        <GoogleDriveIcon iconClassName="cursor-pointer" />
        <label forhtml="video-upload" className="block">
          <img src="./images/local-files.webp" alt="" className="block" />
        </label>
        <input type="file" className="hidden" id="video-upload" />
        <DropBoxIcon iconClassName="cursor-pointer" />
      </div>
    </div>
  );
}

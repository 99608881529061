import React from "react";
// import React, { useRef, useEffect } from "react";
import MetashopLogo from "../../icons/MetashopLogo";
import CloseIcon from "../../icons/Close";
import Sidenav from "./Sidenav";
import Sideaction from "./Sideaction";

function Sidebar({
  showSidebar,
  setShowSidebar,
  setOverlay,
  userInfo,
  path,
  setCurrentState,
  isGaussianUser,
}) {
  const closeSidebar = () => {
    setShowSidebar(false);
    setOverlay(false);
  };

  return (
    <aside
      className={`fixed -left-[256px] top-0 z-20 min-h-screen w-[256px] rounded-br-2xl rounded-tr-2xl bg-accent-lighter p-5 transition-all duration-500 md:static md:w-unset md:rounded-none md:border-r ${
        showSidebar ? "active" : false
      }`}
    >
      <CloseIcon
        iconClassName="toggle-icon mb-10 md:hidden"
        onClick={closeSidebar}
      />
      <MetashopLogo iconClassName="mb-9 mt-7 mx-auto hidden max-w-[72px] md:block" />
      <Sidenav
        path={path}
        setCurrentState={setCurrentState}
        userInfo={userInfo}
        isGaussianUser={isGaussianUser}
      />
      {/* <Sideaction userInfo={userInfo} /> */}
    </aside>
  );
}

export default Sidebar;

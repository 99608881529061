import React from "react";

function SearchIcon({ color, height, width, iconClassName, onClick }) {
  return (
    <svg
      onClick={onClick}
      className={iconClassName}
      width={width || 18}
      height={height || 18}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.112 15.223a7.112 7.112 0 1 0 0-14.224 7.112 7.112 0 0 0 0 14.224zM17 17l-3.867-3.867"
        stroke="#444"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SearchIcon;

import React from "react";

function Discord({ height, width, iconClassName, onClick }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 70 70"
      fill="none"
      onClick={onClick}
      className={iconClassName}
    >
      <rect
        width={width || "70"}
        height={height || "70"}
        rx={(width || 70) / 2}
        fill="#5865F2"
      />
      <path
        d="M50.966 20.015A37.172 37.172 0 0 0 41.476 17c-.448.828-.854 1.68-1.215 2.552a34.239 34.239 0 0 0-10.533 0A27.442 27.442 0 0 0 28.513 17a37.43 37.43 0 0 0-9.498 3.022c-6.006 9.176-7.634 18.124-6.82 26.944A37.8 37.8 0 0 0 23.835 53a29.196 29.196 0 0 0 2.492-4.15 24.421 24.421 0 0 1-3.925-1.936c.33-.247.651-.5.962-.747A26.623 26.623 0 0 0 35 48.85c4.023 0 7.995-.916 11.636-2.684.314.265.636.52.962.747a24.514 24.514 0 0 1-3.933 1.94A28.943 28.943 0 0 0 46.158 53a37.627 37.627 0 0 0 11.646-6.03c.956-10.229-1.631-19.095-6.838-26.955zM27.36 41.542c-2.269 0-4.143-2.126-4.143-4.741 0-2.615 1.809-4.76 4.135-4.76 2.327 0 4.186 2.145 4.147 4.76-.04 2.615-1.828 4.74-4.14 4.74zm15.282 0c-2.272 0-4.139-2.126-4.139-4.741 0-2.615 1.81-4.76 4.14-4.76 2.33 0 4.174 2.145 4.135 4.76-.04 2.615-1.824 4.74-4.136 4.74z"
        fill="#fff"
      />
    </svg>
  );
}

export default Discord;

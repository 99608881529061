import React from "react";

function Crop({ iconClassName, height, width, color, onClick }) {
  return (
    <svg
      onClick={onClick}
      className={iconClassName}
      width={width || "26"}
      height={height || "26"}
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="m6.595 1-.142 16.363a2.182 2.182 0 0 0 2.182 2.181h16.362"
        stroke={color || "#137359"}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m1 6.595 16.363-.142a2.182 2.182 0 0 1 2.181 2.182v16.362"
        stroke={color || "#137359"}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Crop;

import React from "react";
import CreateNewIcon from "../../../../icons/CreateNew";
export default function Index({ text, onClick }) {
  return (
    <div
      onClick={onClick}
      className="relative my-10to20 flex h-36to56 cursor-pointer items-center
          rounded-xl border"
    >
      <div className="flex items-center">
        <CreateNewIcon iconClassName="ml-16to30 mr-2.5 lg:mr-3.5 3xl:mr-5" />
        <h5 className="font-medium line-clamp-1">{text}</h5>
      </div>
    </div>
  );
}

import React from "react";

function DownloadFile({ width, height, color, iconClassName, onClick }) {
  return (
    <svg
      onClick={onClick}
      className={iconClassName}
      width={width || 18}
      height={height || 18}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19 13v4a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-4M15 8l-5 5-5-5M10 13V1"
        stroke={color || "#137359"}
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default DownloadFile;

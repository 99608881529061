import React from "react";

export default function Index() {
  return (
    <div className="tablet-device-error fixed top-0 z-50 hidden h-full w-full bg-accent-lighter md:flex-col md:items-center md:justify-center lg:hidden md:portrait:flex md:landscape:hidden">
      <div className="tablet-ui relative h-[350px] w-[280px] animate-360deg rounded-xl border-8 border-primary before:absolute before:left-1/2 before:top-4 before:h-5 before:w-5 before:-translate-x-2/4 before:rounded-full before:border-4 before:border-primary"></div>
      <h2 className="text-3xl font-bold md:mt-10">Please Rotate Your Device</h2>
    </div>
  );
}

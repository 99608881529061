import React from "react";
import { InlineWidget } from "react-calendly";

export default function Index() {
  return (
    <div className="paypopbox flex h-full w-full flex-col justify-between rounded-3xl">
      <InlineWidget url="https://calendly.com/metashopai/interactive-scenes" />
    </div>
  );
}

import React from "react";

function Pencil({ height, width, iconClassName, iconColor, onClick }) {
  return (
    <svg
      width={width || "30"}
      height={height || "30"}
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
      className={iconClassName}
    >
      <path
        d="M22.707 9.704 20.28 7.28c-.373-.373-1.04-.373-1.413 0L7.593 18.577a.608.608 0 0 0-.16.32l-.426 3.49a.51.51 0 0 0 .16.453c.106.107.24.16.373.16h.053l3.492-.426a.726.726 0 0 0 .32-.16l11.302-11.297a.967.967 0 0 0 .293-.693 1.03 1.03 0 0 0-.293-.72zm-11.915 11.83-2.64.32.32-2.637 9.597-9.593 2.319 2.319-9.596 9.592zM21.134 11.17l-2.319-2.292.773-.772 2.32 2.318-.774.746z"
        fill={iconColor || "#444"}
      />
    </svg>
  );
}

export default Pencil;

import ReactDOM from "react-dom/client";
import {
  BrowserRouter,
  Routes,
  Route,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import Header from "./components/Header";
import Main from "./components/Main";
import Sidebar from "./components/Sidebar";
import TabletFallback from "./components/TabletFallback";
import Overlay from "./components/Overlay";
import ModelFullView from "./pages/ModelFullView";
import DataCards from "./components/DataCards";
import Models3D from "./components/Models3D";
import MyFiles from "./pages/MyFiles";
import NewUser from "./pages/NewUser";
// import Alert from "./components/Alert";
import MyAccount from "./pages/MyAccount";
// import Input from "./components/Input";
import UploadVideo from "./pages/UploadVideo";
import LoginButton from "./components/LoginButton";
import UserProfile from "./pages/UserProfile";
import axios from "axios";
import config from "./config.json";
import Tutorials from "./pages/Tutorials";
import PrimeContact from "./pages/PrimeContact";
import VideoSnapshot from "video-snapshot";
import Modal from "./components/Modal";
import { useAuth0 } from "@auth0/auth0-react";
import Loader from "../src/components/Loader";
import Heading from "./components/Heading";
import Discord from "./icons/Discord";
import { clearAllFailedModal, fetchPendingBills } from "./service/modal";
import WarningIcon from "./icons/warning";
import Close from "./icons/Close";
import ReactGA from "react-ga4";
import moment from "moment";
import NewUserWelcomeModal from "../src/components/Modal/NewUserWelocmeModal";
import NewUserCreditModal from "./components/Modal/NewUserCreditModal";
import InvoiceModal from "./components/Modal/InvoiceModal";
import { removeCreditUsedPopup, removeWelcomePopup } from "./service/home";
import Pricing from "./pages/Pricing";

ReactGA.initialize("G-730MR18DGJ");

function App() {
  const divRef = useRef(null);

  const [showSidebar, setShowSidebar] = useState(false);
  const [showHeader, setShowHeader] = useState(false);
  const [overlay, setOverlay] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [edit3dModel, setEdit3dModel] = useState(false);
  const [isGaussianUser, setIsGaussianUser] = useState(false);
  const [showWelcomeModal, setShowWelcomeModal] = useState(false);
  const [showInvoiceModal, setShowInvoiceModal] = useState(false);
  const [showCreditLeftModal, setShowCreditLeftModal] = useState(false);
  const [uploadCancelToken, setUploadCancelToken] = useState(null);

  const [authToken, setToken] = useState("");
  const [userInfo, setUserInfo] = useState(null);
  const [allProducts, setProducts] = useState([]);
  const [activeProduct, setActiveProduct] = useState(null);
  const [percentCompleted, setPercentComplete] = useState(0);
  const [uploadDone, setUploadedDone] = useState(false);
  const [uploadingProgress, setUploadingProgress] = useState([]);
  const [currentState, setCurrentState] = useState("model");
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [showCalendlyModal, setShowCalendlyModal] = useState(false);
  const [progressInfo, setProgressInfo] = useState([]);
  const [otherInfo, setOtherInfo] = useState([]);
  const [showLoader, setIsLoading] = useState(false);
  const [sizeTooBigModal, setSizeTooBigModal] = useState(false);
  const [searchActive, setSearchActive] = useState(false);
  const [showProcessingWarning, setShowProcessingWarning] = useState(false);
  const location = useLocation();
  const [pendingPaymentData, setPendingPaymentData] = useState([]);
  const [showNewUserPaymentModal, setShowNewUserPaymentModal] = useState(false);
  const [showDesktopHeader, setShowDesktopHeader] = useState(false);
  const {
    loginWithRedirect,
    user,
    isAuthenticated,
    isLoading,
    getAccessTokenSilently,
  } = useAuth0();

  const getPendingBill = async () => {
    const res = await fetchPendingBills(authToken, userInfo?.id);
    if (res) {
      setPendingPaymentData(res);
    }
  };

  useEffect(() => {
    getPendingBill();
  }, []);

  const navigate = useNavigate();

  const logoClicked = () => {
    navigate("/");
  };

  useEffect(() => {
    if (user) {
      setIsLoading(true);
      getAccessTokenSilently().then((token) => {
        localStorage.setItem("token", token);
        setToken(token);
        axios
          .post(
            `${config.backend_url}/v1/users/`,
            { name: user.name, email: user.email },
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then((response) => {
            setIsLoading(false);
            setUserInfo(response.data);
            setShowCreditLeftModal(response.data.creditUsedLimit);
            setIsGaussianUser(response.data.isGaussian);
            setShowWelcomeModal(response.data.newUser);
            getNewProducts(response.data.id, token);
            if (response.data.newUser) {
              navigate("/tutorials");
            }
          });
      });
    } else {
      console.log({ user, userInfo, isLoading, isAuthenticated });
      if (!isAuthenticated && !userInfo && !isLoading) {
        loginWithRedirect();
      }
    }
  }, [user]);

  useEffect(() => {
    if (location?.pathname == "/profile") {
      if (window.innerWidth > 768) {
        setShowSidebar(true);
      } else {
        setShowSidebar(false);
      }
    } else if (
      location?.pathname == "/product" ||
      location?.pathname == "/edit-profile"
    ) {
      setShowHeader(false);
      setShowSidebar(false);
    } else {
      setActiveProduct(null);
      if (window.innerWidth > 768) {
        setShowSidebar(true);
      } else {
        setShowSidebar(false);
      }

      if (location?.pathname == "/prime-contact") {
        if (window.innerWidth > 768) {
          setShowDesktopHeader(false);
        } else {
          setShowDesktopHeader(true);
        }
      } else {
        setShowDesktopHeader(true);
      }
      setShowHeader(true);
    }

    /// setting default 3d scroll position for start position
    if (location?.pathname != "/product" && location?.pathname != "/") {
      localStorage.setItem("3dScrollPosition", 0);
    }
    if (
      allProducts.filter((pitem) => !pitem.processed && !pitem.failed).length &&
      allProducts.some(
        (value) =>
          !value.processed &&
          !value.failed &&
          moment(value.createdAt).isBefore(moment().subtract(3, "hours"))
      )
    ) {
      setShowProcessingWarning(true);
    }
  }, [location]);

  // if (activeProduct) {
  //   return (
  //     <ModelFullView
  //       setEdit3dModel={setEdit3dModel}
  //       edit3dModel={edit3dModel}
  //       activeProduct={activeProduct}
  //       logoClicked={logoClicked}
  //     />
  //   );
  // } else if (uploadVideo) {
  //   return <UploadVideo />;
  // } else if (userProfile) {
  //   return <UserProfile />;
  // } else {

  // console.log(uploadingProgress, "pathnamee");

  useEffect(() => {
    if (uploadDone) {
      setPercentComplete(0);
    }
  }, [uploadDone]);

  const getNewProducts = (userId, token, isSearch, searchText) => {
    if (currentState != "upload") {
      setCurrentState("model");
    }
    axios
      .post(
        `${config.backend_url}/v1/users/getProducts?userId=${
          userId || userInfo.id
        }&searchKeyword=${isSearch && searchText ? searchText : ""}`,
        {},
        {
          headers: { Authorization: `Bearer ${token ? token : authToken}` },
        }
      )
      .then((vidResponse) => {
        setProducts(vidResponse.data);
        // setIsLoading(false);
      });
  };

  const editProduct = (deleteFlag, productId, newName) => {
    const body = {
      deleteFlag,
      productId,
      newName,
    };
    // setIsLoading(true);
    return axios
      .post(
        `${config.backend_url}/v1/users/updateProductNameorMarkDelete`,
        body,
        {
          headers: { Authorization: `Bearer ${authToken}` },
        }
      )
      .then(() => {
        return getNewProducts();
      });
  };

  const uploadFile = async (file, fileNameEntered) => {
    if (file.size / (1024 * 1024) > 5120) {
      setSizeTooBigModal(true);
      setShowUploadModal(false);
      return;
    }

    const previewSrc = URL.createObjectURL(file);

    setCurrentState("upload");

    setOtherInfo([
      ...otherInfo,
      { preview: previewSrc, fileName: fileNameEntered },
    ]);
    let earlierLength = progressInfo.length;
    setShowUploadModal(false);
    const formData = new FormData();

    formData.append("videos", file);
    let uniqueId = Math.random();

    const cancelToken = axios.CancelToken.source();
    setUploadCancelToken(cancelToken);

    const waitTime = 2000;
    const myUploadRequest = axios
      .post(
        `${config.backend_url}/v1/users/upload?userId=${userInfo.id}&name=${fileNameEntered}`,
        formData,
        {
          maxRedirects: 5,
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${authToken}`,
          },
          onUploadProgress: function (progressEvent) {
            var percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            if (percentCompleted == 100) {
              setProgressInfo((previousAr) => {
                let newAr = [...previousAr];
                newAr[earlierLength] = 99;
                return newAr;
              });
            } else {
              setProgressInfo((previousAr) => {
                let newAr = [...previousAr];
                newAr[earlierLength] = percentCompleted;
                return newAr;
              });
            }
          },
          cancelToken: cancelToken.token,
        }
      )
      .then((res) => {
        setProgressInfo((previousAr) => {
          let newAr = [...previousAr];
          newAr[earlierLength] = null;
          return newAr;
        });
        setUploadCancelToken(null);
        getNewProducts(userInfo.id);
        const formData = new FormData();
        formData.append("screenShot", previewSrc);

        axios.post(
          `${config.backend_url}/v1/users/updateProductScreenshot?productId=${res.data?.product?.id}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${authToken}`,
            },
          }
        );
      });

    const timer = new Promise((_, reject) =>
      setTimeout(reject, waitTime, { timedout: "request taking a long time" })
    );

    return Promise.race([myUploadRequest, timer]);
  };

  /// handle 3d modal scroll position
  const handleScroll = () => {
    // Access the scroll position using the scrollTop property of the div reference
    const scrollPosition = divRef.current.scrollTop;
    console.log("Scroll Position:", scrollPosition);
    localStorage.setItem("3dScrollPosition", scrollPosition);
  };

  const clearAllModel = async () => {
    const res = await clearAllFailedModal(authToken, userInfo.id);
    if (res.success) {
      getNewProducts();
    }
  };

  const removeWelcomeModal = async () => {
    const payload = { userId: userInfo.id };
    const res = await removeWelcomePopup(payload);
    if (res.message == "sucess") {
      setShowWelcomeModal(false);
    }
  };

  const removeCreditPopup = async () => {
    const payload = { userId: userInfo.id };
    const res = await removeCreditUsedPopup(payload);
    if (res.message == "sucess") {
      setShowCreditLeftModal(false);
    }
  };

  const handleCancelUpload = () => {
    if (uploadCancelToken) {
      uploadCancelToken.cancel("Upload cancelled by user");
      setProgressInfo([]);
      setCurrentState("model");
    }
  };

  useEffect(() => {
    const handleWindowClose = (event) => {
      if (uploadCancelToken) {
        const confirmationMessage =
          "Upload in progress. Are you sure you want to leave?";
        (event || window.event).returnValue = confirmationMessage; // For IE and Firefox
        return confirmationMessage; // For modern browsers
      }
    };

    window.addEventListener("beforeunload", handleWindowClose);

    return () => {
      window.removeEventListener("beforeunload", handleWindowClose);
    };
  }, [uploadCancelToken]);

  return (
    <>
      {showLoader ? <Loader /> : null}

      {showWelcomeModal ? (
        <NewUserWelcomeModal onClose={() => removeWelcomeModal()} />
      ) : (
        <></>
      )}
      {showCreditLeftModal ? (
        <NewUserCreditModal onClose={() => removeCreditPopup()} />
      ) : (
        <></>
      )}
      {showInvoiceModal ? (
        <InvoiceModal
          onClose={() => setShowInvoiceModal(false)}
          userInfo={userInfo}
          setShowModal={setShowInvoiceModal}
          authToken={authToken}
        />
      ) : (
        <></>
      )}

      <div
        className={`dashboard-wrapper min-h-screen bg-accent-lighter duration-500 ${
          activeProduct ? "bg-white" : "bg-accent-lighter"
        } ${showHeader || showSidebar ? "md:flex" : ""}`}
      >
        {showUploadModal && (
          <Modal
            uploadVideoWithText={true}
            heading="This is Modalaaa"
            btnText="Yes"
            content="this is my contenet"
            userInfo={userInfo}
            setShowModal={setShowUploadModal}
            authToken={authToken}
            uploadFile={uploadFile}
          />
        )}

        {showPaymentModal && (
          <Modal
            paymentModal
            heading="This is Modalaaa"
            btnText="Yes"
            content="this is my contenet"
            userInfo={userInfo}
            setShowModal={setShowPaymentModal}
            authToken={authToken}
          />
        )}

        {showCalendlyModal && (
          <Modal
            calendlyModal
            heading="This is Modalaaa"
            btnText="Yes"
            content="this is my contenet"
            userInfo={userInfo}
            setShowModal={setShowCalendlyModal}
            authToken={authToken}
          />
        )}

        {showNewUserPaymentModal && (
          <Modal
            newUserPaymentModal={showNewUserPaymentModal}
            heading="This is Modalaaa"
            btnText="Yes"
            content="this is my contenet"
            userInfo={userInfo}
            setShowModal={setShowNewUserPaymentModal}
            authToken={authToken}
          />
        )}

        {sizeTooBigModal && (
          <Modal sizeTooBigModal setShowModal={setSizeTooBigModal} />
        )}

        {showHeader && (
          <Header
            setShowSidebar={setShowSidebar}
            authToken={authToken}
            setToken={setToken}
            userInfo={userInfo}
            setUserInfo={setUserInfo}
            setProducts={setProducts}
            logoClicked={logoClicked}
            setPercentComplete={setPercentComplete}
            setUploadedDone={setUploadedDone}
            getNewProducts={getNewProducts}
            setShowUploadModal={setShowUploadModal}
            setSearchActive={setSearchActive}
            setShowPaymentModel={setShowInvoiceModal}
            showOnDesktopView={showDesktopHeader}
            isGaussianUser={isGaussianUser}
          />
        )}
        {showSidebar && (
          <Sidebar
            showSidebar={showSidebar}
            setShowSidebar={setShowSidebar}
            setOverlay={setOverlay}
            userInfo={userInfo}
            isMobile={window.innerWidth > 768}
            path={location?.pathname}
            setCurrentState={setCurrentState}
            isGaussianUser={isGaussianUser}
          />
        )}

        <Routes>
          <Route
            path="/"
            element={
              <Main setOverlay={setOverlay} setShowModal={setShowModal}>
                {/* <NewUser setOverlay={setOverlay} setShowModal={setShowModal} /> */}

                {/* <MyAccount /> */}

                {/* <Input
              label="My file"
              success
              errorMessage="yeh mera error hai"
              successMessage="Yeh mera success hai"
            /> */}

                {/* <MyFiles
              setShowFileActions={setShowFileActions}
              showFileActions={showFileActions}
              allProducts = {allProducts}
            /> */}

                {allProducts.length == 0 &&
                progressInfo.filter((item) => item).length == 0 ? (
                  searchActive ? (
                    <Heading text={`No result found`} />
                  ) : (
                    <NewUser
                      setShowUploadModal={setShowUploadModal}
                      authToken={authToken}
                    />
                  )
                ) : (
                  <div ref={divRef} className="contentBox">
                    {pendingPaymentData.length ? (
                      <div className="paymentPendingBox mb-3">
                        <div className="Pending-title">
                          <img
                            className="warning-mark"
                            src="./images/alert-circle.svg"
                            alt="warning"
                          />
                          Payment Pending:
                        </div>
                        <div className="Pending-text">
                          Please Pay The OutStanding amount to creating 3D
                          models
                        </div>
                        <div
                          className="payBtn"
                          onClick={() => {
                            setShowPaymentModal(true);
                          }}
                        >
                          Pay Now
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}

                    {showProcessingWarning ? (
                      <div className="errormsg mb-5 bg-[#c8f1e1] p-3	">
                        <div className="warnigIconBox">
                          <WarningIcon
                            height={20}
                            width={20}
                            iconClassName={"mr-3"}
                          />
                        </div>
                        <div className="msgTextBox">
                          <span className="msgTitle">
                            Temporary Video Processing Delay: &nbsp;
                          </span>
                          <span className="msgText text-sm">
                            Expect slower video processing; for help, contact us
                            on{" "}
                            <a
                              href="https://discord.com/invite/8YNGyq4Ze4"
                              target="_blank"
                              className="discardTxt"
                            >
                              Discord.
                            </a>{" "}
                            Meanwhile, we are actively working to resolve this
                            issue.
                          </span>
                        </div>
                        <div className="closeIconBox">
                          <Close
                            iconClassName={"cursor-pointer"}
                            onClick={() => {
                              setShowProcessingWarning(false);
                            }}
                          />
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                    {searchActive ? (
                      <Heading text={`Search Result:`} />
                    ) : (
                      <DataCards
                        allProducts={allProducts}
                        uploadingProgress={uploadingProgress}
                        setCurrentState={setCurrentState}
                        currentState={currentState}
                        progressInfo={progressInfo}
                      />
                    )}

                    <Models3D
                      allProducts={allProducts}
                      setActiveProduct={setActiveProduct}
                      uploadingProgress={uploadingProgress}
                      userInfo={userInfo}
                      authToken={authToken}
                      setUserInfo={setUserInfo}
                      setUploadingProgress={setUploadingProgress}
                      getNewProducts={getNewProducts}
                      currentState={currentState}
                      editProduct={editProduct}
                      setCurrentState={setCurrentState}
                      uploadFile={uploadFile}
                      otherInfo={otherInfo}
                      progressInfo={progressInfo}
                      searchActive={searchActive}
                      handleScroll={handleScroll}
                      divRef={divRef}
                      clearAll={clearAllModel}
                      handleCancelUpload={handleCancelUpload}
                    />
                  </div>
                )}
              </Main>
            }
          ></Route>

          <Route
            path="product"
            element={
              <ModelFullView
                setIsLoading={setIsLoading}
                edit3dModel={edit3dModel}
                activeProduct={activeProduct}
                setActiveProduct={setActiveProduct}
                editProduct={editProduct}
                userInfo={userInfo}
              />
            }
          />

          <Route
            path="profile"
            element={
              // <MyAccount
              //   userInfo={userInfo}
              //   setShowHeader={setShowHeader}
              //   setShowSidebar={setShowSidebar}
              // />
              <UserProfile
                setUserInfo={setUserInfo}
                userInfo={userInfo}
                authToken={authToken}
                setShowHeader={setShowHeader}
                setShowSidebar={setShowSidebar}
                setIsLoading={setIsLoading}
              />
            }
          />
          <Route
            path="edit-profile"
            element={
              <UserProfile
                setUserInfo={setUserInfo}
                userInfo={userInfo}
                authToken={authToken}
                setShowHeader={setShowHeader}
                setShowSidebar={setShowSidebar}
                setIsLoading={setIsLoading}
              />
            }
          />
          <Route path="edit-profile" element={<UserProfile />} />

          <Route path="pricing" element={<Pricing userId={userInfo?.id} />} />
          <Route
            path="tutorials"
            element={
              <Tutorials
                token={authToken}
                userId={userInfo?.id}
                setShowPaymentModal={setShowPaymentModal}
              />
            }
          />
          <Route
            path="prime-contact"
            element={
              <PrimeContact
                setShowCalendlyModal={setShowCalendlyModal}
                setShowHeader={setShowHeader}
              />
            }
          />
          {/*  <Route path="contact" element={<Contact />} />
          <Route path="*" element={<NoPage />} /> */}
        </Routes>
      </div>
      <TabletFallback />

      <a
        className="fixed bottom-60to40 right-10to40"
        href="https://discord.gg/8YNGyq4Ze4"
        target="_blank"
      >
        <Discord iconClassName="h-42to70 w-42to70 rounded-full shadow-lg" />
      </a>

      {overlay ? <Overlay visible={overlay} /> : null}
    </>
  );
}
//}

export default App;

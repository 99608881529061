import React from "react";

function Plus({ iconClassName, height, width, color, onClick }) {
  return (
    <svg
      onClick={onClick}
      className={iconClassName}
      width={width || 16}
      height={height || 16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 1v14M1 8h14"
        stroke={color || "#fff"}
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Plus;

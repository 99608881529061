import React from "react";
import CloseIcon from "../../../icons/Close";
// import ViewDetails from "./ViewDetails";
// import ModelActions from "./ModelActions";
// import UploadVideo from "./UploadVideo";
// import QrCode from "../../modals/QrCode";
// import DeleteData from "../../modals/DeleteData";

export default function Index({
  setShowModal,
  setOverlay,
  small,
  large,
  long,
  smallLarge,
  children,
  className,
  index,
  subClassName,
}) {
  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <section
      className={`fixed left-0 top-0 z-40 h-screen w-screen bg-transparent-7 transition ${className}`}
    >
      <div
        className={`myModal absolute bottom-0 left-1/2 z-30 -translate-x-2/4 bg-white ${subClassName} ${
          large
            ? "h-max min-h-[596px] rounded-t-3xl p-20to40 md:top-1/2 md:w-[764px] md:-translate-y-2/4 md:rounded-3xl"
            : smallLarge
            ? "h-max max-h-5/6 rounded-t-3xl p-20to30 pr-0 md:top-16 lg:top-20 lg:w-340to450 lg:rounded-3xl"
            : long
            ? "top-15 h-max w-340to450 rounded-3xl p-20to30 lg:top-20"
            : "top-15 h-184to256 w-340to450 rounded-3xl p-20to30 md:top-16 lg:top-20"
        }`}
      >
        <CloseIcon
          iconClassName={`absolute cursor-pointer ${
            large
              ? "top-6 lg:top-5 right-5"
              : "top-6 lg:top-8 right-6 lg:right-8"
          }`}
          onClick={closeModal}
        />
        {children}
        {/* <QrCode /> */}

        {/* <UploadVideo /> */}

        {/* <DeleteData
          heading="Delete this 3D Model"
          content="Caution: This cannot be undone"
        /> */}

        {/* <ViewDetails /> */}

        {/* <ModelActions
          heading="Move 3D model to"
          setOpenSubFile={setOpenSubFile}
          openSubFile={openSubFile}
        /> */}
      </div>
    </section>
  );
}

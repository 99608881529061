import React from "react";
import Button from "../../Button";

export default function Index({ heading, content, btnText, file }) {
  return (
    <div className="flex h-full flex-col justify-between">
      <div>
        <h4 className="text-20to24 font-semibold">{heading}</h4>
        {file ? (
          <Input
            type="text"
            placeholder="File name goes here"
            className="mt-14to30"
          />
        ) : (
          <p className="mt-14to30">{content}</p>
        )}
      </div>
      <div className="flex justify-between">
        <Button
          text={btnText}
          className="mr-4 w-full md:mr-5"
          onClick={() => {
            console.log("yes clicked");
          }}
        />
        <Button
          text="Cancel"
          outline
          className="w-full"
          onClick={() => {
            console.log("Cancel clicked");
          }}
        />
      </div>
    </div>
  );
}

import React, { useState, useEffect } from "react";
import CopyLink from "../../../components/CopyLink";
import Button from "../../../components/Button";
import BackArrowIcon from "../../../icons/BackArrow";
import moment from "moment";
import DownloadFile from "../../../icons/DownloadFile";
import Delete from "../../../icons/Delete";
import ViewAR from "../../../icons/ViewAR";
import { useLocation, useNavigate } from "react-router-dom";
import PencilIcon from "../../../icons/Pencil";
import QuestionIcon from "../../../icons/question_mark_icon.svg";
import ReactGA from "react-ga4";

export default function Index({
  className,
  activeProduct,
  logoClicked,
  setShowDeletePopup,
  editProduct,
  fetchCurrentProduct,
  onDownload,
}) {
  console.log(activeProduct, "productActivee");
  const location = useLocation();
  const [isGaussian, setIsGaussian] = useState(
    activeProduct?.isGaussian ?? false
  );

  const timestamp = activeProduct.id.toString().substring(0, 8);
  const myDate = new Date(parseInt(timestamp, 16) * 1000);
  var drawer = document.getElementById("drawer");
  var default3dmodel = document.getElementById("default3dmodel");
  var modalLogoWrapper = document.getElementById("modal-logo-wrapper");
  const [beingRenamed, setBeingRenamed] = useState(false);
  const [productName, setProductName] = useState(activeProduct?.product_name);
  const [fileSizeMb, setFileSizeMb] = useState(null);
  var yDown = null;

  const navigate = useNavigate();

  function getTouches(evt) {
    return (
      evt.touches || // browser API
      evt.originalEvent.touches
    ); // jQuery
  }

  function handleTouchStart(evt) {
    const firstTouch = getTouches(evt)[0];
    yDown = firstTouch.clientY;
  }

  function handleTouchMove(evt) {
    if (!yDown) {
      return;
    }

    var yUp = evt.touches[0].clientY;
    var yDiff = yDown - yUp;

    if (yDiff > 5) {
      /* down swipe */
      drawer.classList.remove("bottom-drawer");
      drawer.classList.add("-bottom-px");
      default3dmodel.classList.add("before:h-screen");
      modalLogoWrapper.classList.remove("z-30");
      modalLogoWrapper.classList.add("z-0");
    }
    if (yDiff < -5) {
      /* up swipe */
      drawer.classList.remove("-bottom-px");
      drawer.classList.add("bottom-drawer");
      default3dmodel.classList.remove("before:h-screen");
      modalLogoWrapper.classList.remove("z-0");
      modalLogoWrapper.classList.add("z-30");
    }
    /* reset values */
    yDown = null;
  }

  const editDone = () => {
    editProduct(false, activeProduct?.id, productName).then(() => {
      setBeingRenamed(false);
      fetchCurrentProduct();
    });
  };

  const buttonClickGA = (title) => {
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname,
      title: title,
    });
  };

  // Function to fetch file size
  const fetchFileSize = (url) => {
    fetch(url)
      .then((response) => {
        // Check if the response is successful
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        // Get the Content-Length header to get the file size
        const contentLength = response.headers.get("Content-Length");
        // Convert bytes to megabytes
        const fileSizeMb = contentLength
          ? (contentLength / (1024 * 1024)).toFixed(0)
          : 0;
        // Set the file size state
        setFileSizeMb(fileSizeMb);
      })
      .catch((error) => {
        console.error("Error fetching file size:", error);
      });
  };

  useEffect(() => {
    if (isGaussian) {
      fetchFileSize(activeProduct?.plyLink);
    } else {
      if (activeProduct?.usdzLink) {
        fetchFileSize(activeProduct?.usdzLink);
      } else if (activeProduct?.glbLink) {
        fetchFileSize(activeProduct?.glbLink);
      }
    }
  }, []);

  return (
    <div
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      id="drawer"
      className={`fixed flex w-full flex-col justify-between rounded-t-5 border-t bg-white shadow-base transition-all duration-500 md:static md:w-[562px] lg:relative lg:bottom-unset lg:rounded-none lg:bg-transparent lg:shadow-none ${className}`}
    >
      <div className="relative px-10to40 before:absolute before:left-1/2 before:top-2.5 before:h-1 before:w-10 before:-translate-x-1/2 before:rounded-2xl before:bg-black-lighter lg:before:hidden">
        <BackArrowIcon
          height="20"
          width="20"
          iconClassName="hidden md:block lg:mt-36to56 "
          onClick={logoClicked}
        />
        {/* ************** Add following classes and this comment after after enable edit Option ***********
          border-b-2 border-dotted border-black-dark
        */}
        <div className="mt-28to60 flex items-center justify-between gap-x-3">
          {beingRenamed ? (
            <div className="my-2 w-full text-14to16 xl:my-4">
              <input
                spellcheck="false"
                className="border-x-0 border-b-2 border-t-0 border-dashed border-black outline-none focus:border-x-0 focus:border-t-0 focus:border-primary focus:shadow-unset focus:ring-0"
                autoFocus
                value={productName}
                onChange={(e) => {
                  setProductName(e.target.value);
                }}
              />
            </div>
          ) : (
            <h2 className="h-max text-18to24 line-clamp-2">
              {activeProduct?.product_name}
            </h2>
          )}
          {/* <span className="text-medium cursor-pointer text-primary">Save</span> */}
          {beingRenamed ? (
            <Button
              text="Done"
              small
              className={`ml-0 md:z-20 md:-ml-1`}
              // className="margin-auto hidden bg-accent lg:flex"
              onClick={editDone}
            />
          ) : (
            <PencilIcon
              iconClassName="min-w-9 cursor-pointer"
              width={36}
              height={36}
              onClick={() => setBeingRenamed(true)}
            />
          )}
        </div>

        <div className="mt-0.5 flex md:mt-2 lg:mb-16to70 xl:mt-2.5">
          <h5 className="text-sm text-gray-400  md:text-base">
            {moment(myDate).format("MMM DD yyyy")}{" "}
            {fileSizeMb && (
              <span>
                <span className="ml-1 mr-1">•</span> {fileSizeMb} mb
              </span>
            )}
          </h5>
          {/* <h5 className="mx-2 text-gray-400 ">.</h5> */}
          {/* <h5 className="text-sm text-gray-400 md:text-base">10 mb</h5> */}
        </div>
        {!isGaussian ? (
          <>
            <div className="mb-0 mt-30to40 rounded-20to30 bg-accent-lighter p-20to36 lg:mt-unset">
              <h5 className="mb-20to36 flex justify-center text-center text-14to20 font-semibold">
                Download the model{" "}
                <img
                  onClick={() => {
                    onDownload();
                  }}
                  src={QuestionIcon}
                  className="ml-2 cursor-pointer"
                />
              </h5>
              <div className="grid grid-cols-2 gap-20to30">
                <Button
                  text="GLB"
                  iconClassName="mr-3"
                  Icon={DownloadFile}
                  outline
                  medium
                  onClick={() => {
                    buttonClickGA("GLB Download");
                    window.open(activeProduct?.glbLink);
                  }}
                />
                <Button
                  text="USDZ"
                  iconClassName="mr-3"
                  Icon={DownloadFile}
                  outline
                  medium
                  onClick={() => {
                    buttonClickGA("USDZ Download");
                    window.open(activeProduct?.usdzLink);
                  }}
                />
                <Button
                  text="OBJ"
                  iconClassName="mr-3"
                  Icon={DownloadFile}
                  outline
                  medium
                  onClick={() => {
                    buttonClickGA("OBJ Download");
                    window.open(activeProduct?.objLink);
                  }}
                />
                <Button
                  text="PLY"
                  iconClassName="mr-3"
                  Icon={DownloadFile}
                  outline
                  medium
                  onClick={() => {
                    buttonClickGA("PLY Download");
                    window.open(activeProduct?.plyLink);
                  }}
                />
              </div>
            </div>
            <p className="my-4 text-center text-black-lighter">OR</p>

            <CopyLink
              btnOutline
              link={activeProduct?.model_url}
              btnClassName="mb-8 ml-0"
            />
          </>
        ) : (
          <>
            <CopyLink
              btnOutline
              link={activeProduct?.model_url}
              btnClassName="mb-8 ml-0"
            />
            <p className="my-4 text-center text-black-lighter">OR</p>
            <Button
              className="w-full"
              text="Download PLY"
              iconClassName="mr-3"
              Icon={DownloadFile}
              outline
              medium
              onClick={() => {
                buttonClickGA("PLY Download");
                window.open(activeProduct?.plyLink);
              }}
            />
          </>
        )}
      </div>
      <div
        className="flex cursor-pointer items-center justify-center border-t border-stream"
        onClick={() => {
          setShowDeletePopup(true);
        }}
      >
        <Delete color="#137359" />
        <h5 className="ml-3 py-12to26 text-primary">Delete</h5>
      </div>
    </div>
  );
}

import BurgermenuIcon from "../../icons/Burgermenu";
import MetashopLogoText from "../../icons/MetashopLogoText";
import SearchIcon from "../../icons/SearchIcon";
import Search from "../Search";
import PlusIcon from "../../icons/Plus";
import Button from "../Button";
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import axios from "axios";
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import config from "../../config.json";
import { useLocation, useNavigate } from "react-router-dom";
import ReactGA from "react-ga4";
import { fetchPendingBills } from "../../service/modal";
import { setToken } from "./../../redux/User/user.action";
import { fetchUserCreditPoints } from "../../service/home";

export default function Index({
  setShowSidebar,
  userInfo,
  authToken,
  logoClicked,
  getNewProducts,
  setShowUploadModal,
  setShowPaymentModel,
  setSearchActive,
  showOnDesktopView,
  isGaussianUser,
}) {
  const { loginWithRedirect, user, isAuthenticated, isLoading } = useAuth0();

  const location = useLocation();
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState("");
  const [openSearch, setOpenSearch] = useState(false);
  const [pendingData, setPendingData] = useState([]);
  const [token] = useState(authToken);
  const [creditInfo, setCreditInfo] = useState({});

  const getPendingBill = async () => {
    const res = await fetchPendingBills(token, userInfo.id);
    if (res) {
      setPendingData(res);
    }
  };
  useEffect(() => {
    if (userInfo) {
      getPendingBill();
      getUsersCreditPoints();
    }

    // console.log("asdasasfdsgGSD");
  }, [userInfo, token]);

  const showSidebar = () => {
    setShowSidebar(true);
    // setOverlay(true);
  };

  const openSearchbar = () => {
    setOpenSearch(true);
  };

  useEffect(() => {
    if (!isAuthenticated && !userInfo && !isLoading) {
      loginWithRedirect();
    }
  }, [user, userInfo, isLoading, isAuthenticated]);

  const searchItOut = (e) => {
    {
      if (e?.target?.value) {
        setSearchText(e.target.value);
        setSearchActive(true);
        getNewProducts(userInfo.id, authToken, true, e.target.value);
      } else {
        setSearchText("");
        setSearchActive(false);
        getNewProducts(userInfo.id, authToken, false, "");
      }
    }
  };

  const openUploadGA = () => {
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname,
      title: "Upload Video Page",
    });
  };

  const getUsersCreditPoints = async () => {
    const payload = { userId: userInfo.id };
    const res = await fetchUserCreditPoints(payload);
    setCreditInfo(res);
  };

  return (
    <header className="sticky top-0 z-20 bg-accent-lighter md:absolute md:right-0 md:h-max">
      <div className="h-full border-b border-slate-200 md:hidden">
        <div className="flex items-center justify-between p-3 pt-4">
          <div className="flex items-center">
            <BurgermenuIcon
              className="toggle-icon mr-5"
              onClick={showSidebar}
            />
            <MetashopLogoText iconClassName="ml-3" onClick={logoClicked} />
          </div>
          <div className="forMobilebx">
            {creditInfo?.credit &&
            creditInfo?.useCredit != creditInfo.credit &&
            isGaussianUser ? (
              <div className="forMobile">
                <div className="connbx">
                  <div className="coinimg">
                    <img src="images/coin1.svg" alt="coin" />
                  </div>
                </div>
                <div className="coinleftbx">
                  {55 - creditInfo.useCredit}/{creditInfo.credit}
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>

          <SearchIcon onClick={openSearchbar} />
        </div>

        <div className="flex hidden h-1/2 items-center justify-around">
          <h3 className="relative text-base font-bold text-primary after:absolute after:-bottom-1 after:left-1/2 after:h-0.5 after:w-1/2 after:-translate-x-2/4 after:bg-primary">
            Home
          </h3>
          <h3 className="relative text-base">My Files</h3>
        </div>
      </div>

      {showOnDesktopView ? (
        <div className="md:my-10 md:flex md:justify-between md:pl-15 md:pr-10">
          <div className="hidden w-1/4 md:block">
            <label
              forhtml="default-search"
              className="sr-only mb-2 text-sm font-medium text-gray-900"
            >
              Search
            </label>
            <div className="relative">
              <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3 lg:pl-5">
                <SearchIcon />
              </div>
              <input
                type="search"
                id="default-search"
                className="block w-full rounded-2xl border-0 bg-white p-4 pl-10 text-sm text-[#999] focus:ring-primary lg:pl-12"
                placeholder="Search..."
                onClick={openSearchbar}
                onChange={(e) => searchItOut(e)}
              />
            </div>
          </div>

          <div className="hhdright flex  w-full ">
            <div className="credbx hidden md:flex">
              {creditInfo?.credit &&
              creditInfo?.useCredit != creditInfo.credit &&
              isGaussianUser ? (
                <div className="credbtn">
                  <div className="connbx">
                    <div className="coinimg">
                      <img src="images/coin1.svg" alt="coin" />
                    </div>
                    <div className="cointtx">Credits left</div>
                  </div>
                  <div className="coinleftbx">
                    {55 - creditInfo.useCredit}/{creditInfo.credit}
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
            {/* {
          <div className="fixed bottom-4 left-1/2 flex w-full -translate-x-1/2 justify-around md:static md:w-unset md:translate-x-0">
            {/* {
          user ? null : 
        
          <Button
            outline
            text="Login"
            Icon={UploadIcon}
            onClick={() => loginWithRedirect()}
          /> } */}

            {/* <Button
            text="Capture Video"
            className="md:mr-4 lg:mr-5 xl:mr-6 3xl:mr-9"
            Icon={CaptureIcon}
          /> */}
            {user && userInfo && !isGaussianUser ? (
              <button
                className="getEnterprise_btn"
                onClick={() => {
                  navigate("/prime-contact");
                }}
              >
                <img
                  src="./images/dashboard_star.png"
                  alt=""
                  className="starbtn"
                />
                Get Enterprise 3D
                <img
                  src="./images/dashboard_star.png"
                  alt=""
                  className="starbtn"
                />
              </button>
            ) : null}

            {user && userInfo && location?.pathname != "/prime-contact" ? (
              <Button
                className="creatnewbtn w-11/12 md:w-auto"
                text="Create New"
                Icon={PlusIcon}
                onClick={() => {
                  if (pendingData.length) {
                    setShowPaymentModel(true);
                  } else {
                    setShowUploadModal(true);
                    openUploadGA();
                  }
                }}
              />
            ) : null}

            {/* <form>
            <div>
            <label for ="inputFile">
            sdfsd </label>


              <input type="file" onChange={onFileChange} id="inputFile" style={{display : 'none'}}/>
            </div>
            <div></div>
            {uploading ? "Done" : null}
          </form> */}
          </div>
        </div>
      ) : (
        <></>
      )}

      {openSearch && window.innerWidth < 768 && (
        <Search setOpenSearch={setOpenSearch} searchItOut={searchItOut} />
      )}
    </header>
  );
}

import React, { useEffect, useState } from "react";
import CloseIcon from "../../../icons/Close";
import {
  fetchPendingBills,
  fetchPendingInvoice,
  fetchcheckout,
} from "../../../service/modal";

export default function Index({ onClose, token, userInfo }) {
  const [pendingData, setPendingData] = useState([]);
  const [checkout, setCheckout] = useState([]);
  const [invoiceDetails, setInvoiceDetails] = useState({});
  const getPendingBill = async () => {
    const res = await fetchPendingBills(token, userInfo.id);
    if (res) {
      const dd = await fetchPendingInvoice(res[0]._id, userInfo.id);
      setInvoiceDetails(dd);
      setPendingData(res);
    }
  };

  const getCheckout = async () => {
    const response = await fetchcheckout(
      token,
      userInfo.id,
      pendingData[0]._id
    );

    if (response) {
      window.open(response.url, "_blank");
    }
  };

  useEffect(() => {
    getPendingBill();
  }, [token]);

  return (
    <section
      className={`fixed left-0 top-0 z-40 h-screen w-screen bg-transparent-7 transition `}
    >
      <div
        className={`invpop absolute left-1/2 top-1/2 z-30 w-[320px] -translate-x-1/2 -translate-y-1/2 rounded-3xl ${"h-max  md:left-1/2 md:top-1/2 md:w-[680px] md:-translate-x-1/2 md:-translate-y-1/2 md:rounded-3xl"}`}
      >
        <CloseIcon
          iconClassName={`absolute cursor-pointer ${"top-6 lg:top-5 right-5"}`}
          onClick={onClose}
        />
        <div className="paypopbox flex h-full w-full flex-col justify-between rounded-3xl">
          <div className="inv-ppHeader">
            <h5 className="text-20to24 font-semibold"> </h5>
            <div className="statusbx">
              <div className="statusBtn">
                <div class="Ellipse-59"></div>
                Pending
              </div>
            </div>
          </div>
          {pendingData.map((data) => {
            var userdetails = data.userDetails;

            return (
              <div className="invoice-popup-content-body h-400 overflow-y-auto">
                <div className="invsdtlbox">
                  <div className="blki blk1">
                    <div className="llgo">
                      <div className="logoimg">
                        <img src="./images/logo-small.svg" alt="" />
                      </div>
                      <div className="compadd">
                        MetaShop Pvt. Ltd.
                        <br />
                        A/502, Astonia Royale, Ambegaon Narhe Road, Pune,
                        Maharashtra, India - 411046
                      </div>
                    </div>
                  </div>
                  <div className="blki blk2">
                    <div className="invttx">INVOICE</div>
                  </div>
                  <div className="blki blk3">
                    <div className="billinbx">
                      <p>
                        <strong>Billing to:</strong>
                      </p>
                      <p classcname>
                        <strong>
                          {userdetails?.name} {userdetails?.companyName}
                        </strong>
                      </p>
                      <p className="cadd">{userdetails?.address}</p>
                      <p classcggst>
                        <strong>Tax No.:</strong> {userdetails?.taxId}
                      </p>
                    </div>
                  </div>
                  <div className="blki blk4">
                    <div className="invdtl">
                      <ul className="invdtlul">
                        <li>
                          <span>Invoice Date:</span>{" "}
                          {invoiceDetails.invoiceDate}
                        </li>
                        <li>
                          <span>Invoice No.:</span>
                          {userdetails?.invoiceNumber}
                        </li>
                        <li>
                          <span>Due Date:</span> {invoiceDetails.dueDate}
                        </li>
                        <li>
                          <span>SAC Code:</span> 998314
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="tabledtlbx desktop">
                  <table className="invtable">
                    <tr>
                      <th>Bill for the month</th>
                      <th>No. of 3D models</th>
                      <th>Cost per 3D model</th>
                      <th>Amount</th>
                    </tr>
                    <tr>
                      <td>{invoiceDetails?.billerMonth} </td>
                      <td>{invoiceDetails?.count}</td>
                      <td align="right">{invoiceDetails?.perModule}</td>
                      <td align="right">{invoiceDetails?.amount}</td>
                    </tr>

                    <tr>
                      <td> </td>
                      <td></td>
                      <td align="right">{invoiceDetails?.taxTextFirst}</td>
                      <td align="right">{invoiceDetails?.taxValueFirst}</td>
                    </tr>
                    <tr>
                      <td> </td>
                      <td></td>
                      <td align="right">{invoiceDetails?.taxTextSecond}</td>
                      <td align="right">{invoiceDetails?.taxValueSecond}</td>
                    </tr>
                    <tr>
                      <td></td>
                      <td></td>
                      <td align="right">Roung-off</td>
                      <td align="right">{invoiceDetails?.roundOff}</td>
                    </tr>
                    <tr>
                      <th></th>
                      <th></th>
                      <th align="right">Total</th>
                      <th align="right">{invoiceDetails?.totalAmount}</th>
                    </tr>
                  </table>
                </div>
              </div>
            );
          })}
        </div>
        <div className="popfoottr">
          <div className="fttblkcontent">
            <div className="ftrinst">
              Please make the payment by the due date
              <br />
              Thank you for choosing our API service!
            </div>
            <div className="ftrbtn-grp">
              <button
                class="btnFill"
                onClick={() => {
                  getCheckout();
                }}
              >
                Pay Now
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

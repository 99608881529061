import React from "react";
import UploadingIcon from "../../icons/Uploading";
import ProcessingIcon from "../../icons/Processing";
import Models3DIcon from "../../icons/Models3D";
import FailedIcon from "../../icons/Failed";
import DataCard from "./DataCard";

export default function Index({
  allProducts,
  uploadingProgress,
  setCurrentState,
  currentState,
  progressInfo,
}) {
  
  const processing = allProducts.filter(
    (item) => !item.processed && !item.failed
  ).length;
  const failed = allProducts.filter((item) => item.failed).length;
  const processed = allProducts.filter((item) => item.processed).length;

  return (
    <section className="grid grid-cols-2 grid-rows-1 gap-16to61 md:grid-cols-3 md:grid-rows-none 2xl:grid-cols-4">
      {/* <DataCard
        text="3D Models"
        active={currentState == "model"}
        Icon={Models3DIcon}
        count={processed || 0}
        onClick={() => {
          setCurrentState("model");
        }}
      /> */}
      <DataCard
        text="File Uploading"
        Icon={UploadingIcon}
        count={progressInfo.filter((item) => item)?.length}
        onClick={() => {
          setCurrentState("upload");
        }}
        active={currentState == "upload"}
      />
      <DataCard
        iconBig
        text="File processing"
        Icon={ProcessingIcon}
        count={processing || 0}
        active={currentState == "processing"}
        onClick={() => {
          setCurrentState("processing");
        }}
      />

      <DataCard
        text="Failed Files"
        Icon={FailedIcon}
        count={failed || 0}
        active={currentState == "failed"}
        onClick={() => {
          setCurrentState("failed");
        }}
      />
    </section>
  );
}

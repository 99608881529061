import React from "react";

function Burgermenu({ color, height, width, iconClassName, onClick }) {
  return (
    <svg
      onClick={onClick}
      className={iconClassName}
      width={width || 20}
      height={height || 14}
      viewBox="0 0 20 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 1H1M19 7H1M15 13H1"
        stroke={color || "#444"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Burgermenu;

import React from "react";
import Button from "../../Button";

export default function Index({ yesClicked, noClicked }) {
  return (
    <div className="flex h-full w-full flex-col justify-between rounded-3xl">
      <div>
        <h5 className="text-20to24 font-semibold">Uploading failed</h5>
        <p className="text-14to18 mt-14to30">
          The size of the video is greater than the maximum size limit (5 GB)
        </p>
      </div>
    </div>
  )
}

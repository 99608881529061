import React from "react";

export default function Index({ visible }) {
  return (
    <div
      className={`${
        visible ? "block" : "hidden"
      } fixed top-0 z-20 h-screen w-screen bg-transparent-7`}
    ></div>
  );
}

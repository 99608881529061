import { SET_TOKEN, SET_USER_INFO, SET_UPLOADING_PROGRESS } from './user.types';


const INITIAL_STATE = {

    token: null,
    userInfo: null,
    uploadingProgress : []
};

const reducer = (state = INITIAL_STATE, action) => {

    switch (action.type) {

        case SET_TOKEN:

           return {

             ...state, token: action.payload,

           };

        case SET_USER_INFO:

           return {
              ...state, userInfo: action.payload,

           };
        
        case SET_UPLOADING_PROGRESS: 
        
        return {
          ...state, uploadingProgress: action.payload,

       };   
         default: return state;

    }

};

export default reducer;
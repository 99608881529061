import React from "react";

function LogOut({ height, width, iconClassName, onClick, color }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || "20"}
      height={height || "20"}
      viewBox="0 0 20 20"
      fill="none"
      onClick={onClick}
      className={iconClassName}
    >
      <path
        d="M9.5 17H4.833c-.619 0-1.212-.164-1.65-.456-.437-.291-.683-.687-.683-1.1V4.556c0-.413.246-.809.683-1.1C3.621 3.164 4.214 3 4.833 3H9.5M13.5 14l4-4-4-4M17.5 10h-9"
        stroke={color || "#444"}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default LogOut;

import React from "react";

export default function Index({ Icon, text, className, textClassName }) {
  return (
    <div className={`flex cursor-pointer flex-col justify-center ${className}`}>
      <Icon iconClassName="mx-auto" />
      {text && <span className={`mt-2 ${textClassName}`}>{text}</span>}
    </div>
  );
}

import React, { useEffect, useState } from "react";
import Button from "../../Button";
import { checkoutOfferDetails, fetchOfferDetails, fetchPendingBills, fetchcheckout } from "../../../service/modal";
import { Link } from "react-router-dom";

export default function Index({ yesClicked, noClicked, token, userId }) {
  const [pendingData, setPendingData] = useState({});
  const [checkout, setCheckout] = useState([]);

  const getPendingBill = async () => {
    const res = await fetchOfferDetails(token, userId);
    if (res) {
      setPendingData(res);
    }
  };

  const getCheckout = async () => {
    const response = await checkoutOfferDetails(
      token,
      userId,
      pendingData.id
    );
    if (response) {
      window.open(response.url, "_blank");
    }
  };
  
  useEffect(() => {
    getPendingBill();
  }, [token]);

  return (
    <div className="paypopbox flex h-full w-full flex-col justify-between rounded-3xl">
      <div className="ppHeader">
        <h5 className="text-20to24 font-semibold">Offer Invoice</h5>
        <div className="status">
          <div className="statusBtn">
            <div class="Ellipse-59"></div>
            Pending
          </div>
        </div>
      </div>
      <div className="headData">
      <div className="dataCol dataCol3">
          <div className="dataTitle">Credit</div>
          <div className="dtaDiv">{pendingData.credit}</div>
        </div>
        <div className="dataCol dataCol1">
          <div className="dataTitle">Amount</div>
          <div className="dtaDiv">
            <div className="nnm mb-2">{pendingData.amount}</div>
          </div>
        </div>
        <div className="dataFooter">
          <div className="ftttext">
            <p>Please make the payment by the due date</p>
            <p>Thank you for choosing our service!</p>
          </div>
          <div className="payBtnBlk">
              <Button
                text="Pay Now"
                className="block w-full"
                onClick={()=>{getCheckout()}}
              />
          </div>
        </div>
      </div>
    </div>
  );
}

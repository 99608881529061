import React from "react";

function CreateNew({ onClick, iconClassName, height, width }) {
  return (
    <svg
      onClick={onClick}
      className={iconClassName}
      width={width || "30"}
      height={height || "30"}
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.333 3H5.667A2.667 2.667 0 0 0 3 5.667v18.666A2.667 2.667 0 0 0 5.667 27h18.666A2.667 2.667 0 0 0 27 24.333V5.667A2.667 2.667 0 0 0 24.333 3z"
        fill="#C8F1E2"
      />
      <path
        d="M15 9v12M9 15h12"
        stroke="#137359"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default CreateNew;

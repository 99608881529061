import React from "react";

function Undo({ iconClassName, height, width, color, onClick }) {
  return (
    <svg
      onClick={onClick}
      className={iconClassName}
      width={width || "22"}
      height={height || "20"}
      viewBox="0 0 22 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 1.992v6h6"
        stroke={color || "#444"}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.51 12.99a9 9 0 1 0 2.13-9.36L1 7.99"
        stroke={color || "#444"}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Undo;

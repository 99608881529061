import React from "react";
import TutorialsIcon from "../../../icons/Tutorials";
import HomeIcon from "../../../icons/Home";
import Menu from "./Menu";
import { Link } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import LogOutIcon from "../../../icons/LogOut";
import MyAccountIcon from "../../../icons/MyAccount";
import PricingIcon from "../../../icons/PricingIcon";
import StarIcon from "../../../icons/Star";

export default function Index({
  userInfo,
  path,
  setCurrentState,
  isGaussianUser,
}) {
  const { logout } = useAuth0();
  return (
    <nav className="lg:mt-16">
      <Link to="/">
        <Menu
          text="Home"
          iconWidth="23"
          iconHeight="24"
          Icon={HomeIcon}
          className={`lg:mx-auto ${path == "/" ? "active" : ""}`}
          iconClassName="mr-1 lg:w-15to22 lg:h-16to26 lg:ml-0"
          iconColor={`${path == "/" ? "#137359" : "#444"}`}
          onClick={() => {
            setCurrentState("model");
          }}
        />
      </Link>
      {/* <Menu
        text="My Files"
        iconWidth="26"
        iconHeight="22"
        Icon={MyFilesIcon}
        className="lg:mx-auto"
        iconClassName="lg:-16to26 lg:h-15to22"
      />
      <Menu
        text="CPC Tracker"
        iconWidth="33"
        iconHeight="32"
        Icon={CpctrackerIcon}
        className="lg:mx-auto"
        iconClassName="relative -right-1.5 lg:w-22to34 lg:h-20to30 lg:static"
      /> */}
      <Link to="/tutorials">
        <Menu
          text="Tutorials"
          iconWidth="29"
          iconHeight="22"
          Icon={TutorialsIcon}
          iconColor={`${path == "/tutorials" ? "#137359" : "#444"}`}
          className={`lg:mx-auto ${path == "/tutorials" ? "active" : ""}`}
          iconClassName="relative -right-1 lg:w-20to30 lg:h-15to22 lg:static"
        />
      </Link>
      {isGaussianUser ? (
        <Link to="/pricing">
          <Menu
            text="Pricing"
            iconWidth="29"
            iconHeight="22"
            Icon={PricingIcon}
            iconColor={`${path == "/pricing" ? "#137359" : "#444"}`}
            className={`lg:mx-auto ${path == "/pricing" ? "active" : ""}`}
          />
        </Link>
      ) : (
        <></>
      )}

      {/* <Link to="/prime-contact">
        <Menu
          text="Prime-contact"
          iconWidth="29"
          iconHeight="29"
          Icon={StarIcon}
          iconColor={`${path == "/prime-contact" ? "#137359" : "#444"}`}
          className={`lg:mx-auto ${
            path == "/prime-contact" ? "active" : ""
          } w-full lg:hidden`}
          iconClassName="relative -right-1 lg:w-20to30 lg:h-15to22 lg:static"
        />
      </Link> */}
      {userInfo && (
        <>
          <Link to="/profile">
            <Menu
              text="My Account"
              iconWidth="23"
              iconHeight="24"
              Icon={MyAccountIcon}
              iconColor={`${path == "/profile" ? "#137359" : "#444"}`}
              className={`lg:hidden ${path == "/profile" ? "active" : ""}`}
              iconClassName="mr-1 lg:w-15to22 lg:h-16to26 lg:ml-0"
            />
          </Link>
          <Menu
            tag="button"
            text="Log Out"
            iconWidth="23"
            iconHeight="24"
            Icon={LogOutIcon}
            className="w-full lg:hidden"
            iconClassName="mr-1 lg:w-15to22 lg:h-16to26 lg:ml-0"
            onClick={() => logout({ returnTo: window.location.origin })}
          />
          <Link to="/profile">
            <div
              className="fixed bottom-5 hidden rounded-full bg-accent-darker p-1 ring-2 ring-accent lg:grid lg:h-17 lg:w-17 lg:place-items-center"
              alt="user-profile"
            >
              <span className="font-semibold">
                {userInfo?.name
                  .split(" ")
                  .map((n) => n[0])
                  .join(" ")
                  .toUpperCase()}
              </span>
            </div>
          </Link>
        </>
      )}
    </nav>
  );
}

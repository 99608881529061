import React, { useState } from "react";
import Button from "../Button";
import ReactGA from "react-ga4";
import { useLocation } from "react-router-dom";

export default function Index({
  link,
  uploading,
  className,
  btnClassName,
  small,
  btnOutline,
  productId,
}) {
  const location = useLocation();
  const [copiedLink, setCopiedLink] = useState("");

  const copyLink = () => {
    navigator.clipboard.writeText(link);
    setCopiedLink(productId);
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname,
      title: "Copy Link",
    });

    setTimeout(() => {
      setCopiedLink("");
    }, 5000);
  };
  
  return (
    <>
      {/* <div
        className={`rounded-tl-xl rounded-bl-xl border-2 border-r-0 border-dashed border-primary px-2 line-clamp-1 ${
          small ? "h-30to40 leading-30to40" : "h-36to56 leading-36to56"
        } ${className}`}
      >
        {link}
      </div> */}
      <Button
        outline={btnOutline}
        copiedLink
        small={small}
        className={`copy-link -ml-2 w-full ${uploading ? "hidden" : "block"} ${
          copiedLink == productId
            ? "md:border-accent md:bg-accent md:text-black"
            : null
        } ${btnClassName}`}
        text={copiedLink == productId ? "Copied Successfully!" : "Copy Link"}
        onClick={copyLink}
      />
    </>
  );
}

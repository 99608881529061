import React, { useEffect } from "react";
export default function Index({ setShowCalendlyModal, setShowHeader }) {
  return (
    <main className="mmdiv mt-3 w-full overflow-y-auto p-3 md:order-2 md:min-h-unset md:pl-10 md:pr-10">
      <div className="prime_top-card">
        <div className="prime_top-card-text">
          Premium Enterprise is here! Upgrade to the the latest in 3D creation
        </div>
        <a
          className="prime_top-card-btn"
          href="https://share.hsforms.com/11fTxb6lLTaCwQdAOjZWG7Aq8f6u"
          target="_blank"
        >
          Contact Us
        </a>
        <img
          src="./images/dashboard_star.png"
          alt=""
          className="prime_st2 star1"
        />
        <img
          src="./images/dashboard_star.png"
          alt=""
          className="prime_st star2"
        />
        <img
          src="./images/dashboard_star.png"
          alt=""
          className="prime_st2 star3"
        />
        <img
          src="./images/dashboard_star.png"
          alt=""
          className="prime_st star4"
        />
      </div>
      <div className="prime_horseImg">
        <img
          className="prime_horseImg-web"
          src="./images/prime_horse.jpg"
          alt=""
        />
        <img
          className="prime_horseImg-mob"
          src="./images/prime_horse-mob.jpg"
          alt=""
        />
      </div>
    </main>
  );
}

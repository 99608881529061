import React from "react";
import Button from "../../Button";

export default function Index({ yesClicked, noClicked }) {
  return (
    <div className="flex h-full w-full flex-col justify-between rounded-3xl">
      <div>
        <h5 className="text-20to24 font-semibold">Clear Failed 3D Model</h5>
        <p className="text-14to18 mt-14to30">
          Are you sure you want to clear all failed 3D model?
        </p>
      </div>
      <div className="flex justify-between gap-15to22">
        <Button
          text="Yes, Clear All"
          className="block w-full"
          onClick={yesClicked}
        />
        <Button
          outline
          text="Cancel"
          className="block w-full"
          onClick={noClicked}
        />
      </div>
    </div>
  );
}

import React from "react";
import MyFile from "./MyFile";
import NewFile from "./NewFile";
import Button from "../../Button";
import SubFile from "./SubFile";

export default function Index({ heading, openSubFile }) {
  openSubFile = false;
  return (
    <div className="">
      <h4 className="text-20to24 font-semibold">{heading}</h4>
      <div className="max-h-screen-50 overflow-y-auto pr-20to30 scrollbar-thin scrollbar-thumb-accent-scroll scrollbar-thumb-rounded-xl after:max-h-[52vh]">
        <MyFile text="File 1">
          {openSubFile && (
            <div className="ml-4 max-h-[42vh] overflow-y-auto scrollbar-thin scrollbar-thumb-accent-scroll scrollbar-thumb-rounded-xl">
              <SubFile text="SubFile 1" />
              <SubFile text="SubFile 2" />
              <SubFile text="SubFile 3" />
              <SubFile text="SubFile 4" />
              <SubFile text="SubFile 5" />
              <SubFile text="SubFile 6" />
              <SubFile text="SubFile 7" />
            </div>
          )}
        </MyFile>
        <MyFile text="File 2" />
        <MyFile text="File 3" />
        <MyFile text="File 4" />
        <MyFile text="File 5" />
        <MyFile text="File 6" />
        <MyFile text="File 7" />
        <MyFile text="File 8" />
      </div>
      <div className="pr-7">
        <h6 className="text-center">Or</h6>
        <NewFile text="File 5" />
        <Button text="Add" className="w-full" />
      </div>
    </div>
  );
}

import React from "react";

export default function Index({ title, description }) {
  return (
    <div className="my-5 flex text-sm last:mb-0">
      <h6 className="min-w-[120px] after:content-[':']">{title}</h6>
      <h6 className="font-medium">{description}</h6>
    </div>
  );
}

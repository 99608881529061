import React, { useId } from "react";
export default function Index({
  fileUpload,
  text,
  type,
  outline,
  link,
  small,
  medium,
  className,
  onClick,
  onChange,
  Icon,
  iconColor,
  iconHeight,
  iconWidth,
  iconClassName,
}) {
  const inputId = useId();
  if (fileUpload) {
    return (
      <>
        <label
          htmlFor={inputId}
          className={`cursor-pointer whitespace-nowrap ${className} ${
            Icon && "flex items-center justify-center"
          } ${
            outline
              ? "rounded-xl border border-primary px-4 text-primary md:px-5 lg:px-6"
              : link
              ? "border-b border-primary text-primary"
              : "rounded-xl bg-primary px-4 text-white md:px-5 lg:px-6"
          } ${small ? "h-30to40" : medium ? "h-42to56" : "h-36to56"}`}
        >
          {Icon ? (
            <Icon
              iconClassName={`${text && "mr-2"} ${iconClassName}`}
              color={outline ? "#137359" : iconColor ? iconColor : "#fff"}
              height={iconHeight}
              width={iconWidth}
            />
          ) : (
            false
          )}
          {text}
        </label>
        <input
          type="file"
          onChange={onChange}
          id={inputId}
          accept=".mov,.mp4"
          className="hidden"
          multiple="multiple"
        />
      </>
    );
  } else {
    return (
      <button
        type={type}
        onClick={onClick}
        className={`whitespace-nowrap rounded-xl px-4 md:px-5 lg:px-6 ${
          Icon && "align-center flex items-center justify-center"
        } ${
          outline
            ? "border border-primary text-primary"
            : "bg-primary text-white"
        } ${
          small ? "h-30to40" : medium ? "h-42to56" : "h-36to56"
        } ${className}`}
      >
        {Icon ? (
          <Icon
            iconClassName={`${text && "mr-2"} ${iconClassName}`}
            color={outline ? "#137359" : iconColor ? iconColor : "#fff"}
            height={iconHeight}
            width={iconWidth}
          />
        ) : (
          false
        )}
        {text}
      </button>
    );
  }
}

import React, { useState, useRef, useEffect } from "react";
import CopyLink from "../../CopyLink";
import Dots3Vertical from "../../../icons/Dots3Vertical";
import ThreeDotMenu from "../../FileActions/index";
import clickedOutside from "../../../utils/clickedOutside";
import Button from "../../Button";
import Modal from "../../Modal";
import ReactGA from "react-ga4";
import { useLocation } from "react-router-dom";
import WarningIcon from "../../../icons/warning";
import moment from "moment";

export default function Index({
  text,
  link,
  src,
  clicked,
  uploading,
  percent,
  processing,
  productId,
  editProduct,
  setShowDeletePopup,
  setModalIdToDelete,
  failed,
  openInNewTab,
  allValue,
  showVideImagePrev,
  stopUploadingVideo,
}) {
  console.log(productId, "getiing");
  const location = useLocation();
  // const [modelName, setModelName] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [productName, setProductName] = useState("");
  const [beingRenamed, setBeingRenamed] = useState(false);

  const editDone = () => {
    editProduct(false, productId, productName).then(() => {
      setBeingRenamed(false);
    });
  };

  const showRightClickView = (e) => {
    e.preventDefault();
    if (!processing) {
      setShowMenu(true);
    }
  };

  //  useEffect(() => {
  //   alert(productName)
  //  }, [productName])

  const editableRef = useRef(null);
  clickedOutside(editableRef, () => {
    setBeingRenamed(false);
  });

  const onRename = () => {
    setBeingRenamed(true);
    setShowMenu(false);
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname,
      title: "Rename Model",
    });
  };

  const onDelete = () => {
    setShowMenu(false);
    setModalIdToDelete(productId);
    setShowDeletePopup(true);
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname,
      title: "Delete Model",
    });

    // editProduct(true,productId)
  };

  const stopUploading = () => {
    stopUploadingVideo();
  };

  useEffect(() => {
    setProductName(text);
  }, []);

  const [processingStages, setProcessingStages] = useState([
    { stage: "Preparing Video", duration: 5 * 60 * 1000, percentage: 5 },
    { stage: "Preprocessing Capture", duration: 5 * 60 * 1000, percentage: 20 },
    { stage: "Building 3D scene", duration: 20 * 60 * 1000, percentage: 50 },
    { stage: "Texturing 3D asset", duration: 45 * 60 * 1000, percentage: 80 },
    { stage: "Exporting 3D asset", duration: 60 * 60 * 1000, percentage: 95 },
    {
      stage: "Creating embed link",
      duration: 60 * 60 * 1000 + 10 * 60 * 1000,
      percentage: 98,
    },
    {
      stage: "Done",
      duration: 60 * 60 * 1000 + 11 * 60 * 1000,
      percentage: 100,
    },
  ]);

  const getProgressStatus = (dateTime) => {
    if (
      moment(dateTime).isBefore(moment().subtract(0, "minutes")) &&
      moment(dateTime).isAfter(moment().subtract(5, "minutes"))
    ) {
      return { progress: 5, state: "Preparing Video" };
    } else if (
      moment(dateTime).isBefore(moment().subtract(5, "minutes")) &&
      moment(dateTime).isAfter(moment().subtract(20, "minutes"))
    ) {
      return { progress: 20, state: "Preprocessing Capture" };
    } else if (
      moment(dateTime).isBefore(moment().subtract(20, "minutes")) &&
      moment(dateTime).isAfter(moment().subtract(45, "minutes"))
    ) {
      return { progress: 50, state: "Building 3D scene" };
    } else if (
      moment(dateTime).isBefore(moment().subtract(45, "minutes")) &&
      moment(dateTime).isAfter(moment().subtract(60, "minutes"))
    ) {
      return { progress: 80, state: "Texturing 3D asset" };
    } else if (
      moment(dateTime).isBefore(moment().subtract(60, "minutes")) &&
      moment(dateTime).isAfter(moment().subtract(71, "minutes"))
    ) {
      return { progress: 95, state: "Exporting 3D asset" };
    } else if (
      moment(dateTime).isBefore(moment().subtract(71, "minutes")) &&
      moment(dateTime).isAfter(moment().subtract(80, "minutes"))
    ) {
      return { progress: 98, state: "Creating embed link" };
    } else {
      return { progress: 100, state: "Preprocessing Capture" };
    }
  };

  return (
    <div
      ref={editableRef}
      className={`promod relative mt-3/4 cursor-pointer rounded-20to30 ${
        uploading ? "h-unset bg-white p-10to30" : "bg-white p-10to30"
      } `}
      onContextMenu={showRightClickView}
    >
      <div className="relative" onClick={clicked}>
        {uploading && !processing ? (
          <img
            className="delet"
            src="./images/delete.svg"
            alt=""
            onClick={() => {
              stopUploading();
            }}
          />
        ) : (
          <></>
        )}

        {(uploading && !processing) || showVideImagePrev ? (
          <video
            src={src}
            height="300"
            width="300"
            loading="lazy"
            alt="nosi"
            className="relative mx-auto -mt-3/4 aspect-square h-auto w-max rounded-20to30 object-contain md:-mt-5/6 lg:-mt-7/10 3xl:-mt-3/4"
          />
        ) : (
          <img
            src={src}
            height="300"
            width="300"
            loading="lazy"
            alt="image cannot be fetched"
            className="relative mx-auto -mt-3/4 aspect-square h-auto w-140to300 rounded-20to30 object-contain md:-mt-5/6 lg:-mt-7/10 3xl:-mt-3/4"
          />
        )}

        <div className="card-warning">
          {moment(allValue?.createdAt).isBefore(
            moment().subtract(3, "hours")
          ) ? (
            <WarningIcon height={20} width={20} iconClassName={"mr-3"} />
          ) : (
            <></>
          )}
        </div>
        {!failed && processing ? (
          <div>
            <div className="progress-info absolute bottom-22to48 left-14to30 text-14to16  text-white xl:p-0">
              {`${getProgressStatus(allValue.createdAt).progress}%`}{" "}
              {`${getProgressStatus(allValue.createdAt).state}`}
            </div>
            <div className="progress-info absolute bottom-14to30 w-full px-14to30">
              <div className="relative h-1.5 w-full	rounded-3xl bg-gray-300">
                <span
                  className="absolute h-full rounded-3xl bg-accent"
                  style={{
                    width: `${getProgressStatus(allValue.createdAt).progress}%`,
                  }}
                ></span>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}

        {uploading && (
          <>
            <div className="max-w-140to300 absolute top-0 h-full max-h-140to300 w-full rounded-xl bg-upload ">
              {failed && (
                <h6 className="absolute bottom-14to30 left-14to30 text-14to16 text-white">
                  Please try uploading again
                </h6>
              )}
              {!failed && !processing && (
                <>
                  <div className="absolute bottom-22to48 left-14to30 text-14to16 font-semibold text-white xl:p-0">
                    {`${percent}%`}
                  </div>
                  <div className="absolute bottom-14to30 w-full px-14to30">
                    <div className="relative h-1.5 w-full	rounded-3xl bg-gray-300">
                      <span
                        className="absolute h-full rounded-3xl bg-accent"
                        style={{ width: `${percent}%` }}
                      ></span>
                    </div>
                  </div>
                </>
              )}
            </div>
          </>
        )}
      </div>
      <div className="flex items-center justify-between">
        {beingRenamed ? (
          <div
            onClick={() => setBeingRenamed(true)}
            className="my-2 w-full text-14to16 xl:my-4"
          >
            <input
              spellcheck="false"
              className="w-full border-x-0 border-b-2 border-t-0 border-dashed border-black outline-none focus:border-x-0 focus:border-t-0 focus:border-primary focus:shadow-unset focus:ring-0"
              autoFocus
              value={productName}
              onChange={(e) => {
                setProductName(e.target.value);
              }}
            />
          </div>
        ) : (
          <h3
            title={!processing && "Rename"}
            className={`${
              uploading
                ? "mt-10to30 md:line-clamp-1"
                : "my-2 cursor-text line-clamp-1 xl:my-4"
            } text-14to16`}
            // contentEditable={modelName}

            onClick={(e) => {
              if (!processing) {
                setBeingRenamed(true);
                e.stopPropagation();
              }
            }}
          >
            {text}
          </h3>
        )}
        {!uploading && (
          <div className="p-2" onClick={() => setShowMenu(true)}>
            <Dots3Vertical />
          </div>
        )}
      </div>
      {showMenu && (
        <ThreeDotMenu
          setMenu={setShowMenu}
          onRename={onRename}
          onDelete={onDelete}
          onOpenInNewTab={openInNewTab}
        />
      )}
      {beingRenamed ? (
        <Button
          small={true}
          className={`ml-0 w-full md:z-20 md:-ml-1`}
          text="Save"
          onClick={editDone}
        />
      ) : (
        !processing &&
        !uploading && (
          <>
            <Button
              small
              outline
              text="View 3D Model"
              className="hidden w-full xl:block"
              onClick={clicked}
            ></Button>
            {/* <CopyLink
              small
              className="absolute -z-10 w-0 md:static md:z-10 md:w-auto"
              btnClassName="ml-0 md:-ml-1"
              link={link}
              uploading={uploading}
              productId={productId}
            /> */}
          </>
        )
      )}
    </div>
  );
}

import React from "react";

export default function Index({ text }) {
  return (
    <li className="mb-30to40 flex items-start last:mb-0">
      <img
        src="./images/lightbulb.png"
        alt="lightbulb"
        height="18"
        width="18"
        className="mt-1 mr-3 text-14to16"
      />
      <span>{text}</span>
    </li>
  );
}

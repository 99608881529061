import React from "react";
import MyFileIcon from "../../../../icons/MyFile";
export default function Index({
  text,
  onClick,
  children,
  className,
  openSubFile,
}) {
  openSubFile = false;
  return (
    <div
      onClick={onClick}
      className={`relative my-10to20 flex cursor-pointer rounded-xl border ${
        openSubFile
          ? "h-max flex-col bg-accent-lighter pt-3"
          : "h-36to56 items-center"
      } ${className}`}
    >
      <div className={`flex items-center`}>
        <MyFileIcon
          iconClassName="ml-16to30 mr-2.5 lg:mr-3.5 3xl:mr-5"
          width="32"
          height="24"
        />
        <h5 className="font-medium line-clamp-1">{text}</h5>
      </div>
      {children}
    </div>
  );
}

import React, { useEffect, useState } from "react";
import Button from "../../components/Button";
import PlusIcon from "../../icons/Plus";
import ReactGA from "react-ga4";
import { useLocation, useNavigate } from "react-router-dom";
import { fetchDefaultModels } from "../../service/modal";

export default function Index({
  setOverlay,
  setShowModal,
  authToken,
  setShowUploadModal,
}) {
  const navigate = useNavigate();
  const [dummyModels, setDummyModels] = useState([]);

  const getDefaultModels = async () => {
    const res = await fetchDefaultModels(authToken);
    console.log("JOFIJ", res);
    setDummyModels(res);
  };

  const location = useLocation();

  const uploadVideo = () => {
    setOverlay(true);
    setShowModal(true);
  };

  const openUploadGA = () => {
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname,
      title: "Upload Video Page",
    });
  };

  useEffect(() => {
    if (authToken) {
      getDefaultModels();
    }
  }, [authToken]);

  return (
    <>
      <section className="newuserDashboard">
        <h1 className="hdd1 text-24to34">3D models (0)</h1>
        <div className="newuserDashboardRow">
          <div className="newuserDashboardItemBx flex w-full justify-center">
            <div className="newuserDashboardItem item2 flex w-full justify-center">
              <video id="bannerVideo" controls className="modimg" autoPlay>
                <source src="/videos/demo_video.mp4" type="video/mp4" />
              </video>
            </div>
          </div>
          {dummyModels &&
            dummyModels?.map((value, index) => {
              return (
                <div
                  className="newuserDashboardItemBx crdmobwidth"
                  onClick={() => {
                    navigate(`/product?id=${value.id}`);
                  }}
                >
                  <div className="newuserDashboardItem item2 cursor-pointer">
                    {/* {index == 0 && (
                      <div className="infor infor2">
                        <p>This a output of same model from video animation</p>
                      </div>
                    )} */}

                    <div className="modimgBx1">
                      <img
                        className="modimg1"
                        src={value?.model_screenshot_url}
                        alt=""
                      />
                    </div>
                    <div className="cardbtm">
                      <div className="optbtngrp">
                        {value.product_name}
                        {/* <button className="dotbtn">
                          <img src="./images/dots.svg" alt="" />
                        </button> */}
                      </div>
                      <Button
                        small
                        outline
                        text="View 3D Model"
                        className="hidden w-full xl:block"
                        onClick={() => {
                          navigate(`/product?id=${value.id}`);
                        }}
                      ></Button>
                    </div>
                  </div>
                </div>
              );
            })}
          {/* <div className="newuserDashboardItemBx">
            <div className="newuserDashboardItem item2">
              <div className="modimgBx1">
                <img className="modimg1" src="./images/mod3.png" alt="" />
              </div>
              <div className="cardbtm">
                <div className="optbtngrp">
                  Sample Output 1
                  <button className="dotbtn">
                    <img src="./images/dots.svg" alt="" />
                  </button>
                </div>
                <a href="" className="btnoutline">
                  View 3D model
                </a>
              </div>
            </div>
          </div>
          <div className="newuserDashboardItemBx">
            <div className="newuserDashboardItem item2">
              <div className="modimgBx1">
                <img className="modimg1" src="./images/mod4.png" alt="" />
              </div>
              <div className="cardbtm">
                <div className="optbtngrp">
                  Sample Output 1
                  <button className="dotbtn">
                    <img src="./images/dots.svg" alt="" />
                  </button>
                </div>
                <a href="" className="btnoutline">
                  View 3D model
                </a>
              </div>
            </div>
          </div> */}
        </div>
      </section>
      {/* <section className="blank-dashboard xl:mx-auto xl:flex xl:items-center">
        <img
          src="./images/fallback-vector.webp"
          alt=""
          className="order-5 mx-auto"
        />
        <div className="text-center xl:grow-2 xl:text-left">
          <h4 className="px-16 text-18to28 xl:px-0">
            Welcome to MetaShop - Version 1.0
            <span className="relative -top-2 left-2 rounded bg-primary px-1 py-0.5 text-xs font-medium text-white lg:-top-4">
              Beta
            </span>
          </h4>
          <h2 className="mt-3 px-16 text-24to34 lg:px-0 lg:font-medium xl:mb-8">
            Let’s create millions of 3D assets together
          </h2>
          <div className="blank-btns fixed bottom-5 right-0 flex w-full justify-around xl:static xl:justify-start">
            
            <Button
              text="Create New"
              Icon={PlusIcon}
              className="hidden md:flex xl:mr-9"
              onClick={() => {
                setShowUploadModal(true);
                openUploadGA();
              }}
            />
          </div>
        </div>
      </section> */}
    </>
  );
}

import React from "react";
import { useNavigate } from "react-router-dom";

function MetashopLogo({ color, height, width, iconClassName }) {
  const navigate = useNavigate();

  return (
    <div className="relative">
      <svg
        className={`cursor-pointer ${iconClassName}`}
        width={width || 72}
        height={height || 40}
        viewBox="0 0 72 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        onClick={() => navigate("/")}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M35.76 9.405 42.07 0l18.302 30.047-5.91 9.912-12.22-19.306L35.76 9.405z"
          fill="#8FD7B3"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="m60.373 30.047-5.91 9.912 11.507-.007L72 29.975l-11.627.072z"
          fill="#137359"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M42.071 0H54.03l17.97 29.974-11.626.073L42.07 0z"
          fill="#C8F1E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 30.006 18.216.001l18.302 30.046-5.91 9.913-12.22-19.306L6.854 40 0 30.006z"
          fill="#8FD7B3"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.388 20.656 6.854 40.001l11.532-.044 5.93-9.935-5.928-9.366zM36.517 30.048l-5.91 9.913 11.507-.007 6.03-9.977-11.627.071z"
          fill="#137359"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.216.002h11.957l17.97 29.975-11.626.072L18.216.002z"
          fill="#C8F1E2"
        />
      </svg>
      <span className="absolute -right-4 -top-4 hidden rounded bg-primary px-1 py-0.5 text-xs font-medium text-white lg:block">
        Beta
      </span>
    </div>
  );
}

export default MetashopLogo;

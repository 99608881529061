import React from "react";

function Dots3Vertical({ width, height, color, iconClassName, onClick }) {
  return (
    <svg
      onClick={onClick}
      className={iconClassName}
      width={width || 3}
      height={height || 15}
      viewBox="0 0 3 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.5 8a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1zM1.5 2a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1zM1.5 14a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z"
        fill={color || "#999"}
        stroke={color || "#999"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Dots3Vertical;

import ModalBase from "./Base";
import Action from "./Action";
import UploadVideo from "./UploadVideo";
import ReUploadVideo from "./ReUploadVideo";
import UploadVideoWithText from "./UploadVideoWithText";
import QrCode from "./QrCode";
import ViewDetails from "./ViewDetails";
import ModelActions from "./ModelActions";
import CalendlyModal from "./CalendlyModal";
import DeleteModal from "./DeleteModal";
import CancelUploading from "./CancelUpload";
import DownloadPopup from "./DownloadModal";
import PaymentModal from "./PaymentModal";
import NewUserPaymentModal from "./NewUserPaymentModal";
import ClearAllModal from "./ClearAllModal";
import Loader from "../Loader";
import SizeBigModal from "./SizeBigModal";
import { useEffect } from "react";
export default function Index({
  action,
  btnText,
  file,
  heading,
  content,
  qrCode,
  uploadVideo,
  viewDetails,
  modelActions,
  reUploadVideo,
  uploadVideoWithText,
  userInfo,
  setShowModal,
  authToken,
  uploadingProgress,
  setUploadingProgress,
  uploadFile,
  index,
  deleteModal,
  clearAllModal,
  yesClicked,
  noClicked,
  qrLink,
  loading,
  sizeTooBigModal,
  setSizeTooBigModal,
  paymentModal,
  newUserPaymentModal,
  cancelUploading,
  downloadPopup,
  calendlyModal,
}) {
  useEffect(() => {
    // console.log("token====", authToken);
  }, [authToken]);
  return (
    <>
      <ModalBase
        large={
          qrCode ||
          uploadVideo ||
          paymentModal ||
          newUserPaymentModal ||
          downloadPopup ||
          calendlyModal
        }
        // small={uploadVideoWithText}
        subClassName={`${uploadVideoWithText ? "mymod" : ""} ${
          downloadPopup ? "ddnpop" : ""
        }`}
        smallLarge={modelActions}
        long={reUploadVideo}
        className={`${qrCode && "hidden lg:block"} ${
          paymentModal && "payPop"
        }  ${newUserPaymentModal && "payPop"}`}
        setShowModal={setShowModal}
      >
        {action ? (
          <Action
            btnText={btnText}
            file={file}
            heading={heading}
            content={content}
          />
        ) : qrCode ? (
          <QrCode qrLink={qrLink} />
        ) : uploadVideo ? (
          <UploadVideo />
        ) : viewDetails ? (
          <ViewDetails />
        ) : modelActions ? (
          <ModelActions heading={heading} />
        ) : reUploadVideo ? (
          <ReUploadVideo />
        ) : uploadVideoWithText ? (
          <UploadVideoWithText uploadFile={uploadFile} />
        ) : deleteModal ? (
          <DeleteModal yesClicked={yesClicked} noClicked={noClicked} />
        ) : cancelUploading ? (
          <CancelUploading yesClicked={yesClicked} noClicked={noClicked} />
        ) : downloadPopup ? (
          <DownloadPopup yesClicked={yesClicked} noClicked={noClicked} />
        ) : calendlyModal ? (
          <CalendlyModal />
        ) : paymentModal ? (
          <PaymentModal
            yesClicked={yesClicked}
            noClicked={noClicked}
            token={authToken}
            userId={userInfo?.id}
          />
        ) : newUserPaymentModal ? (
          <NewUserPaymentModal
            yesClicked={yesClicked}
            noClicked={noClicked}
            token={authToken}
            userId={userInfo?.id}
          />
        ) : clearAllModal ? (
          <ClearAllModal yesClicked={yesClicked} noClicked={noClicked} />
        ) : sizeTooBigModal ? (
          <SizeBigModal />
        ) : null}
      </ModalBase>
    </>
  );
}

import React from "react";

export default function Index({ url, title, text }) {
  return (
    <div className="mb-10 h-full basis-1/4 rounded-xl bg-white lg:mb-unset">
      <iframe
        src={url}
        title={title}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
        className="h-50 w-full rounded-xl"
      ></iframe>

      <div className="rounded-b-xl bg-white p-5 md:p-6 lg:p-7">{text}</div>
    </div>
  );
}

import React from "react";
import CloseIcon from "../../../icons/Close";
import { useNavigate } from "react-router-dom";

export default function Index({ onClose }) {
  const navigate = useNavigate();
  return (
    <section
      className={`fixed left-0 top-0 z-40 h-screen w-screen bg-transparent-7 transition `}
    >
      <div
        className={`myModal1a bbb absolute left-1/2 top-1/2 z-30 w-[320px] -translate-x-1/2 -translate-y-1/2 rounded-3xl ${"h-max p-20to40 md:left-1/2 md:top-1/2 md:w-[580px] md:-translate-x-1/2 md:-translate-y-1/2 md:rounded-3xl"}`}
      >
        <CloseIcon
          iconClassName={`absolute cursor-pointer ${"top-6 lg:top-5 right-5"}`}
          onClick={onClose}
        />
        <div className="paypopbox flex h-full w-full flex-col justify-between rounded-3xl">
          <div className="ppHeader">
            {/* <h5 className="text-20to24 font-semibold">Welcome Popup</h5>
            <div className="status">
              <div className="statusBtn">
                <div class="Ellipse-59"></div>
              </div>
            </div> */}
          </div>

          <div className="pop-content-body">
            <div className="pop-icon">
              <img src="./images/starx.svg" alt="" />
            </div>
            <div className="pop-msg-title">
              Heads up! <br /> You've used all your 55 credits!
            </div>
            <div className="popmsgtxtbx">
              <div className="msg-bbt">
                <p className="mb-4">
                  <strong>
                    From now on, you'll be charged based on the number of models
                    you create each month.
                  </strong>
                </p>
                <p className="mb-4">
                  You can see our{" "}
                  <a
                    onClick={() => {
                      navigate("/pricing");
                    }}
                    className="ulink"
                  >
                    pricing plans
                  </a>{" "}
                  to estimate your charges
                </p>
                <p>
                  We hope you enjoyed using your credits! <br /> Keep creating
                  amazing 3D models!
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

import { requestWithToken } from "../helpers/helpers";

const clearAllFailedModal = async (token, userId) => {
  try {
    const response = await requestWithToken(
      "DELETE",
      `/v1/users/deleteProducts?userId=${userId}`
    );
    return response.data;
  } catch (err) {
    return err.response.data;
  }
};
const fetchPendingBills = async (token, userId) => {
  try {
    const response = await requestWithToken(
      "GET",
      `/billing/pending?userId=${userId}`
    );
    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const fetchPendingInvoice = async (invoiceId, userId) => {
  try {
    const response = await requestWithToken(
      "GET",
      `/billing/invoice?userId=${userId}&invoiceId=${invoiceId}`
    );
    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const fetchcheckout = async (token, userId, invoiceId) => {
  try {
    const response = await requestWithToken(
      "GET",
      `/billing/checkout?userId=${userId}&invoiceId=${invoiceId}`
    );
    return response?.data;
  } catch (err) {
    return err.response.data;
  }
};

const fetchOfferDetails = async (token) => {
  try {
    const response = await requestWithToken("GET", `/offer/details`);
    return response?.data;
  } catch (err) {
    return err.response.data;
  }
};

const checkoutOfferDetails = async (token, userId, offerId) => {
  try {
    const response = await requestWithToken(
      "GET",
      `/offer/checkout?userId=${userId}&offerId=${offerId}`
    );
    return response?.data;
  } catch (err) {
    return err.response.data;
  }
};

const fetchDefaultModels = async (token) => {
  try {
    const response = await requestWithToken(
      "POST",
      `/v1/users/getHomeDemoProducts`,
      {}
    );
    return response.data;
  } catch (err) {
    return err.response;
  }
};

const fetchInvoiceHistory = async (userId) => {
  try {
    const response = await requestWithToken(
      "GET",
      `/billing/all?userId=${userId}`
    );
    return response.data;
  } catch (err) {
    return err.response;
  }
};

const fetchInvoicePdf = async (userId, invoiceId) => {
  try {
    const response = await requestWithToken(
      "GET",
      `/billing/pdf?userId=${userId}&invoiceId=${invoiceId}`,
      {},
      "blob"
    );
    return response.data;
  } catch (err) {
    return err.response;
  }
};

export {
  clearAllFailedModal,
  fetchPendingBills,
  fetchcheckout,
  fetchOfferDetails,
  checkoutOfferDetails,
  fetchDefaultModels,
  fetchInvoiceHistory,
  fetchPendingInvoice,
  fetchInvoicePdf,
};

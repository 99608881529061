import React from "react";
import { QRCode } from "react-qrcode-logo";
import logo from "../../../icons/MetashopLogo";

export default function Index({ qrLink }) {
  console.log(qrLink, "qrlinkkkk");
  return (
    <div
      className="h-full w-full rounded-3xl p-6 text-center md:items-center md:justify-center lg:flex
        lg:flex-col"
    >
      {/* <img src="./images/metashop-qr.webp" alt="" className="order-3 block" /> */}
      <h2 className="text-3xl font-medium">
        {/* Select a video or drag and drop here */}
        Please scan the QR code
      </h2>
      <h5 className="my-5 text-xl">
        Video Capture is available for mobile devices only.
      </h5>

      <QRCode
        value={qrLink}
        size="400"
        logoPadding="10"
        logoPaddingStyle="circle"
        fgColor="#137359"
        logoImage={
          "https://storage.googleapis.com/metashop-test-bucket/MetaShop%20-%20Icon%20-%20White.png"
        }
        removeQrCodeBehindLogo={true}
      />
    </div>
  );
}

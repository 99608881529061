import React, { useEffect, useState } from "react";
import Button from "../../Button";
import {
  fetchPendingBills,
  fetchPendingInvoice,
  fetchcheckout,
} from "../../../service/modal";
import { Link } from "react-router-dom";

export default function Index({ yesClicked, noClicked, token, userId }) {
  const [pendingData, setPendingData] = useState([]);
  const [checkout, setCheckout] = useState([]);
  const getPendingBill = async () => {
    const res = await fetchPendingBills(token, userId);
    const dd = await fetchPendingInvoice(res._id, userId);
    console.log("DJIo", dd);
    if (res) {
      setPendingData(res);
    }
  };

  const getCheckout = async () => {
    const response = await fetchcheckout(token, userId, pendingData[0]._id);

    if (response) {
      window.open(response.url, "_blank");
    }
  };

  useEffect(() => {
    getPendingBill();
  }, [token]);

  return (
    <div className="paypopbox flex h-full w-full flex-col justify-between rounded-3xl">
      <div className="ppHeader">
        <h5 className="text-20to24 font-semibold">Monthly Invoice</h5>
        <div className="status">
          <div className="statusBtn">
            <div class="Ellipse-59"></div>
            Pending
          </div>
        </div>
      </div>
      <div className="headData">
        {pendingData.map((data) => {
          var userdetails = data.userDetails;
          var userDetails = data.userDetails;
          return (
            <>
              <div className="dataRow">
                <div className="dataCol dataCol1">
                  <div className="dataTitle">Invoice Number</div>
                  <div className="dtaDiv">{data._id}</div>
                </div>
                <div className="dataCol dataCol2">
                  <div className="dataTitle">Date</div>
                  <div className="dtaDiv">{data.createdAt}</div>
                </div>
                <div className="dataCol dataCol3">
                  <div className="dataTitle">Due Date</div>
                  <div className="dtaDiv">{data.updatedAt}</div>
                </div>
                <div className="dataCol dataCol1">
                  <div className="dataTitle">Bill To</div>
                  <div className="dtaDiv">
                    <div className="nnm mb-2">{userdetails.name}</div>
                    <div className="addrs">{userdetails.companyName}</div>
                  </div>
                </div>
                <div className="dataCol dataCol2">
                  <div className="dataTitle">Email id</div>
                  <div className="dtaDiv">{userdetails.email}</div>
                </div>
                <div className="dataCol dataCol3">
                  <div className="dataTitle">Phone number</div>
                  <div className="dtaDiv">{userdetails.phone}</div>
                </div>
              </div>

              <div className="ppHeader">
                <h5 className="text-20to24 font-semibold">Invoice Details</h5>
              </div>

              <div className="invoiceBlock">
                <table className="invoiveTable">
                  <tr>
                    <th>Description</th>
                    <th>No. of Calls</th>
                    <th>Rate/Call</th>
                    <th>Total</th>
                  </tr>
                  <tr>
                    <td>API Calls for 3D Modeling</td>
                    <td>100</td>
                    <td>$ {data.amount}</td>
                    <td>$ {data.totalAmount}</td>
                  </tr>
                </table>
                <div className="sumTableBlock">
                  <table className="sumTable">
                    <tr>
                      <td>Subtotal</td>
                      <td>$ {data.totalAmount}</td>
                    </tr>
                    <tr>
                      <td>TAX {data.tax}%</td>
                      <td>$ 36.00</td>
                    </tr>
                    <tr>
                      <td>
                        <span className="ttlm">Total Amount Due</span>
                      </td>
                      <td>
                        <span className="ttlm">$ {data.taxAmount}</span>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </>
          );
        })}
        <div className="dataFooter">
          <div className="ftttext">
            <p>Please make the payment by the due date</p>
            <p>Thank you for choosing our API service!</p>
          </div>
          <div className="payBtnBlk">
            <Button
              text="Pay Now"
              className="block w-full"
              onClick={() => {
                getCheckout();
              }}
            />
          </div>
        </div>
        {/* <ul className="dataUl">
    <li>
      <div className="dataTitle">Invoice Number</div>
      <div className="dtaDiv">0001982</div>
    </li>
    <li>
      <div className="dataTitle">Date</div>
      <div className="dtaDiv">November 05, 2020</div>
    </li>
    <li>
      <div className="dataTitle">Due Date</div>
      <div className="dtaDiv">December 05, 2020</div>
    </li>
    <li>
      <div className="dataTitle">Bill To</div>
      <div className="dtaDiv">
      <div className="nnm mb-2">Mr. Rajan Anand</div>
      <div className="addrs">Lockwood Enterprises, street name, City name, pin code - 7890897</div>
        </div>
    </li>
    <li>
      <div className="dataTitle">Email id</div>
      <div className="dtaDiv">rajan@blahblah.com</div>
    </li>
    <li>
      <div className="dataTitle">Phone number</div>
      <div className="dtaDiv">+91 9998887770</div>
    </li>
  </ul> */}
      </div>

      {/* <div className="flex justify-between gap-15to22">
        <Button
          text="Yes, Delete"
          className="block w-full"
          onClick={yesClicked}
        />
        <Button
          outline
          text="Cancel"
          className="block w-full"
          onClick={noClicked}
        />
      </div> */}
    </div>
  );
}

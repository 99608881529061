import React from "react";

export default function Index({
  count,
  text,
  Icon,
  iconWidth,
  iconHeight,
  iconBig,
  onClick,
  active
}) {
 
  console.log(active, 'activeee', text)

  return (
    count > 0 ?
    <div className={`rounded-20to40 px-16to50 py-16to42 cursor-pointer hover:bg-accent-darker  ${active ? "bg-accent-darker": "bg-white"}`} onClick = {onClick}>
      <div className="flex justify-between">
        <h2 className="text-24to66 font-medium">{count}</h2>
        <Icon
          iconClassName={`${
            iconBig ? "w-42to90 h-42to90" : "w-38to74 h-38to74"
          }`}
          height={iconHeight}
          width={iconWidth}
        />
      </div>
      <h4 className="mt-16to26 text-14to26">{text}</h4>
    </div> : null
  );
}

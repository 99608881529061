import React from "react";
import CloseIcon from "../../../icons/Close";

export default function Index({ onClose }) {
  return (
    <section
      className={`fixed left-0 top-0 z-40 h-screen w-screen bg-transparent-7 transition `}
    >
      <div
        className={`myModal1a bbb absolute left-1/2 top-1/2 z-30 w-[320px] -translate-x-1/2 -translate-y-1/2 rounded-3xl ${"h-max p-20to40 md:left-1/2 md:top-1/2 md:w-[580px] md:-translate-x-1/2 md:-translate-y-1/2 md:rounded-3xl"}`}
      >
        <CloseIcon
          iconClassName={`absolute cursor-pointer ${"top-6 lg:top-5 right-5"}`}
          onClick={onClose}
        />
        <div className="paypopbox flex h-full w-full flex-col justify-between rounded-3xl">
          <div className="ppHeader">
            {/* <h5 className="text-20to24 font-semibold">Welcome Popup</h5>
            <div className="status">
              <div className="statusBtn">
                <div class="Ellipse-59"></div>
              </div>
            </div> */}
          </div>

          <div className="pop-content-body">
            <div className="pop-icon">
              <img src="./images/confetti.svg" alt="" />
            </div>
            <div className="pop-msg-title">
              Congratulations! <br /> 55 credits added to your account!
            </div>
            <div className="popmsgtxtbx">
              <div className="pop-msgttx-box">
                <p className="fs14">This includes:</p>
                <ul className="popul">
                  <li>50 credits from your recent payment.</li>
                  <li>5 free bonus credits.</li>
                </ul>
              </div>
              <div className="msg-bbt">
                <p>
                  <strong>1 credit is equivalent to 1 3D model.</strong>
                </p>
                <p>
                  Please note that once you use these 55 credits, you will be
                  charged at the end of the month based on your number of models
                  created during the month.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

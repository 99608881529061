import React from "react";

function MyFile({ height, width, iconClassName }) {
  return (
    <svg
      className={iconClassName}
      width={width || "36"}
      height={height || "28"}
      viewBox="0 0 36 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m30.706 10.279.333-5.182c.095-1.49-1.2-2.715-2.665-2.715H13.356L10.42 0H3.283C1.818 0 .62 1.221.62 2.714V25.834l30.086-15.555z"
        fill="#0F7358"
      />
      <path
        d="M33.336 6.512H8.245c-1.472 0-2.98 1.215-3.37 2.714l-4.17 16.06C.315 26.785 1.193 28 2.665 28h25.09c1.472 0 2.98-1.215 3.37-2.714l4.17-16.06c.39-1.499-.488-2.714-1.96-2.714z"
        fill="#90D7B3"
      />
    </svg>
  );
}

export default MyFile;

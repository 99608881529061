import React from "react";

function Failed({ height, width, iconClassName }) {
  return (
    <svg
      className={iconClassName}
      width={width || "76"}
      height={height || "74"}
      viewBox="0 0 76 74"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m0 14.77 34.142 17.241-5.404 9.648 9.183 10.159V74L0 54.852V14.771z"
        fill="#90D7B3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 14.803 26.392 1.537l-4.508 16.458 15.502 8.224-3.225 5.757L0 14.802v.002zM46.552 0 76 14.803 48.724 28.515l4.47-5.877L38 14.805 46.553 0 46.552 0z"
        fill="#C8F1E1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M75.842 14.77 48.786 28.431l-8.133 10.696L46.62 49.45l-8.197 24.297 37.42-18.895V14.77zm-37.92 37.045V74l.075-.038-.07-22.14-.005-.006z"
        fill="#268C71"
      />
    </svg>
  );
}

export default Failed;

import React from "react";

function Delete({ width, height, color, iconClassName, onClick }) {
  return (
    <svg
      className={iconClassName}
      onClick={onClick}
      width={width || 30}
      height={height || 30}
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 10.2h14M20.444 10.2v11.2c0 .424-.164.831-.456 1.131-.291.3-.687.469-1.1.469h-7.777c-.413 0-.809-.169-1.1-.469a1.624 1.624 0 0 1-.456-1.131V10.2m2.334 0V8.6c0-.424.163-.831.455-1.131.292-.3.688-.469 1.1-.469h3.111c.413 0 .808.169 1.1.469.292.3.456.707.456 1.131v1.6M13.445 14.2V19M16.555 14.2V19"
        stroke={color || "#444"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Delete;

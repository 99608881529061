import React, { useEffect, useState } from "react";
import MetashopLogo from "../../icons/MetashopLogo";
import BackArrowIcon from "../../icons/BackArrow";
import MobileEditActions from "./MobileEditActions";
import DefaultData from "./DefaultData";
import DefaultActions from "./DefaultActions";
import DesktopEditActions from "./DesktopEditActions";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../../components/Button";
import ViewARIcon from "../../icons/ViewAR";
import Modal from "../../components/Modal";
import queryString from "query-string";
import { useLocation, useSearchParams } from "react-router-dom";
import config from "../../config.json";
import axios from "axios";
import ReactGA from "react-ga4";

export default function Index({
  edit3dModel,
  activeProduct,
  setActiveProduct,
  editProduct,
  userInfo,
  setIsLoading,
}) {
  const [showQrCode, setShowQrCode] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [showDownloadPopup, setDownloadPopup] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  console.log(activeProduct, "activeProduct");
  const logoClicked = () => {
    setActiveProduct(null);
    navigate("/");
  };

  const deleteModel = () => {
    editProduct(true, activeProduct?.id).then(() => {
      logoClicked();
    });
  };

  useEffect(() => {
    fetchCurrentProduct();
    if (location) {
      ReactGA.send({
        hitType: "pageview",
        page: location.pathname,
        title: "Model Detail Page",
      });
    }
  }, [location]);

  const fetchCurrentProduct = () => {
    const productId = searchParams.get("id");
    setIsLoading(true);
    axios
      .post(
        `${config.backend_url}/v1/users/getProductById?productId=${productId}`,
        {},
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      )
      .then((vidResponse) => {
        setActiveProduct(vidResponse.data);
        setIsLoading(false);
      });
  };

  return (
    activeProduct && (
      <>
        {showQrCode ? (
          <Modal
            qrCode
            qrLink={activeProduct?.model_url}
            setShowModal={setShowQrCode}
          />
        ) : null}

        {showDeletePopup ? (
          <Modal
            deleteModal
            userInfo={userInfo}
            yesClicked={deleteModel}
            noClicked={() => {
              setShowDeletePopup(false);
            }}
            setShowModal={() => {
              setShowDeletePopup(false);
            }}
          />
        ) : null}
        <section className="relative h-screen max-h-screen w-full md:flex">
          <div
            id="modal-logo-wrapper"
            className="relative z-30 py-2 md:absolute md:left-6 md:top-5 3xl:top-10"
            onClick={logoClicked}
          >
            <BackArrowIcon iconClassName="absolute left-2 top-6 md:hidden" />
            <MetashopLogo iconClassName="mx-auto" />
          </div>
          <div
            id="default3dmodel"
            className={`${
              edit3dModel
                ? "edit3dmodel"
                : "default3dmodel mt-1 border-t border-stream transition duration-500 before:fixed before:left-0 before:top-0 before:w-full before:bg-transparent-7 lg:mt-0 lg:border-0 lg:before:hidden"
            } relative grid w-full place-items-center border-stream transition-all lg:h-full lg:border-r`}
          >
            <iframe
              style={{ width: "100%", height: "100%" }}
              src={activeProduct.model_url}
              loading="eager"
              allowFullScreen={true}
            ></iframe>
            <Button
              text="View in AR"
              Icon={ViewARIcon}
              className="absolute bottom-4 hidden bg-accent 2xl:flex"
              onClick={() => {
                setShowQrCode(true);
              }}
            />
            {/* <DefaultActions
            setEdit3dModel={setEdit3dModel}
            className={edit3dModel ? "flex md:hidden" : null}
          /> */}
            {edit3dModel && <DesktopEditActions />}
          </div>

          <DefaultData
            className={edit3dModel ? "hidden md:flex" : null}
            activeProduct={activeProduct}
            setShowDeletePopup={setShowDeletePopup}
            logoClicked={logoClicked}
            editProduct={editProduct}
            fetchCurrentProduct={fetchCurrentProduct}
            onDownload={() => {
              setDownloadPopup(true);
            }}
          />
          {edit3dModel && <MobileEditActions />}

          {showDownloadPopup ? (
            <Modal
              downloadPopup
              userInfo={userInfo}
              yesClicked={() => {
                setDownloadPopup(false);
              }}
              noClicked={() => {
                setDownloadPopup(false);
              }}
              setShowModal={() => {
                setDownloadPopup(false);
              }}
            />
          ) : null}
        </section>
      </>
    )
  );
}


    import { combineReducers } from 'redux';


    import userReducer from './User/user.reducer';


    const rootReducer = combineReducers({

        counter: userReducer,

    });

    export default rootReducer;